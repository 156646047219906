export enum QueryParms {
  Display = 'display'
}

export enum WidgetVariant {
  Overlay = 'overlay',
  Embedded = 'embedded',
}

export enum DesignVersion {
  V1 = '1',
  V2 = '2',
}

export enum CryptoDonationWorkflowStep {
  Pledge,
  Notes,
  FillDonorInfo,
  Error,
  Donate,
}

export enum CreditCardDonationWorkflowStep {
  Frequency,
  Pledge,
  Notes,
  FillDonorInfo,
  FillCardInfo,
  Error,
  Donate,
  Success,
}

export enum StockDonationWorkflowStep {
  Pledge,
  Notes,
  FillDonorInfo,
  FillBrokerInfo,
  SignPledge,
  Error,
  Success,
}

export enum DafDonationWorkflowStep {
  Pledge,
  FillDonorInfo,
  Donate,
  Notes,
  Error,
  Success,
}

export enum AchDonationWorkflowStep {
  Pledge,
  Notes,
  FillDonorInfo,
  Mandate,
  Plaid,
  Error,
  Success,
}

export type WorkflowStep =
  CryptoDonationWorkflowStep |
  CreditCardDonationWorkflowStep |
  StockDonationWorkflowStep |
  DafDonationWorkflowStep |
  AchDonationWorkflowStep;

export enum DonationWorkflowType {
  Crypto = 'crypto',
  CreditCard = 'card',
  Stock = 'stock',
  Daf = 'daf',
  Ach = 'ach',
}

export default DonationWorkflowType;
