import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  header: {
    backgroundColor: '#291B4F',
    height: 88,
    borderRadius: '4px 4px 0 0',
  },
  footer: {
    backgroundColor: '#291B4F',
    height: 48,
    borderRadius: '0 0 4px 4px',
  },
  content: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    padding: 6,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 512,
    justifyContent: 'space-between',
    paddingTop: 0,
    paddingBottom: 0,
  },
  logo: {
    width: 276,
    marginBottom: 32,
  },
  prompt: {
    fontSize: 20,
    textAlign: 'center',
  },
});

export default useStyles;
