import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import fetchDonationAmount, { FetchDonationAmountParams } from 'state/chariot/thunk/fetchDonationAmount.thunk';
import postDonationInfo, { PostDonationInfoParams } from 'state/chariot/thunk/postDonationInfo.thunk';
import updatePledge from 'state/pledge/updatePledge';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import { DonationPledge } from 'types/pledge';
import { DafDonationWorkflowStep } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';

import DafConfirm from './dafConfirm';
import OverlayDafConfirm from './overlayVariant/dafConfirm';

const mapStateToProps = (state: AppReduxState) => {
  const { donation, details } = state.chariot;
  const { externalId } = state.donationData;
  const { organization } = state.organization;
  const { fundraiserId } = state.fundraiser;
  const { donor } = state;
  const { pledge } = state;

  const workflowSessionId = donation.session?.workflowSessionId ?? '';
  const amount = donation.session?.grantIntent.amount ?? 0;
  const DAFDonationInfo = {
    organizationId: organization.id,
    workflowSessionId,
    donor,
    pledge,
    pledgeAmount: amount,
    fundraiserId,
    coverTransactionFees: pledge.coverTransactionFees,
    campaignId: pledge.campaignId,
    externalId,
  };

  return {
    workflowSessionId: donation.session?.workflowSessionId ?? '',
    amount: donation.session?.grantIntent.amount ?? 0,
    fetchedAmount: details.amount,
    pledge,
    isFinalizing: donation.isLoading,
    finalizationError: donation.error,
    isFetchingFinalAmount: details.isLoading,
    finalAmountErrror: details.error,
    organization,
    fundraiserId,
    donor,
    campaignId: pledge.campaignId,
    DAFDonationInfo,
    externalId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  updatePledge: (changes: Pick<DonationPledge, 'coverTransactionFees'>) => dispatch(updatePledge.createAction(changes)),
  fetchDonationAmount: (params: FetchDonationAmountParams) => dispatch(fetchDonationAmount(params)),
  goBack: () => dispatch(changeStep.createAction(DafDonationWorkflowStep.FillDonorInfo)),
  goToNotes: () => dispatch(changeStep.createAction(DafDonationWorkflowStep.Notes)),
  postDonationInfo: (params: PostDonationInfoParams) => dispatch(postDonationInfo(params)),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => ({
  ...stateProps,
  ...dispatchProps,
  fetchDonationAmount: (coverTransactionFees: boolean) => dispatchProps.fetchDonationAmount({
    organizationId: stateProps.organization.id,
    amountInCents: stateProps.amount,
    coverTransactionFees,
  }),
  postDonationInfo: () => {
    const {
      areNotesEnabled,
      areTributesEnabled,
    } = stateProps.pledge;
    const shouldGoToNotes = areNotesEnabled || areTributesEnabled;
    if (shouldGoToNotes) {
      dispatchProps.goToNotes();
      return;
    }

    dispatchProps.postDonationInfo(stateProps.DAFDonationInfo);
  },
});

export const DafConfirmConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(DafConfirm, OverlayDafConfirm));

export default DafConfirmConnect;
