import { makeStyles } from 'tss-react/mui';

import COLORS from 'theme/colors';

const useStyles = makeStyles()({
  page: {
    padding: '30px 30px 45px 30px',
  },
  nextButton: {
    flex: 1,
    height: 50,
    fontSize: 16,
    borderRadius: '5px !important',
    color: '#291B4F !important',
    lineHeight: '24px',
    background: '#FCD42B !important',
    boxShadow: 'none !important',
    textTransform: 'none !important' as 'none',
    '&:disabled, &.Mui-disabled': {
      border: 0,
      background: '#F0F4F8 !important',
      color: '#928E9C !important',
    },
  },
  nextButtonContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    color: COLORS.TEXT.PRIMARY,
  },
  contentContainer: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  pageHeader: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Poppins !important',
    margin: '20px 0 0 0',
    color: '#291B4F',
  },
  dialogPaper: {
    width: 260,
    background: 'non',
  },
  modalContainer: {
    overflow: 'hidden',
    bottomLeftBorderRadius: 8,
    bottomRightBorderRadius: 8,
  },
  formHelperText: {
    marginLeft: 74,
  },
  iconExchangeWrapper: {
    width: '16px',
    height: '16px',
    background: '#FFFFFF',
    border: '1px solid #DADFE5',
    boxSizing: 'border-box',
    borderRadius: '50%',
    display: 'flex',
    color: '#86909F',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    cursor: 'pointer',
    zIndex: '999',
  },
  contentContainerPadded: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  fieldHeader: {
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 4,
    marginTop: 10,
    width: '100%',
    textAlign: 'left',
    color: '#aaa',
  },
  stockBox: {
    padding: '0 8px',
    height: '100%',
    cursor: 'pointer',
  },
  activeCurrency: {
    fontSize: 12,
  },
  stocksLabel: {
    fontSize: 12,
    color: '#86909F',
  },
  inputWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  input: {
    textAlign: 'left',
    paddingLeft: 8,
    width: '100%',
    margin: '0px !important',
  },
  mainInput: {
    fontSize: '13px !important',
  },
  absolute: {
    position: 'absolute',
  },
  stockQuantityInput: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '22px',
    padding: '0!important',
    paddingLeft: '4px !important',
    display: 'inline',
  },
  subPrice: {
    color: '#86909F',
    paddingRight: 0,
    fontSize: 14,
    maxWidth: 152,
    marginRight: '8px',
    overflow: 'hidden',
    textAlign: 'left',
    width: '100%',
    paddingLeft: 8,
  },
  currency: {
    position: 'absolute',
    right: -30,
    top: -5,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '15px',
    fontFamily: 'Roboto',
    lineHeight: '48px',
    textTransform: 'uppercase',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    color: '#000',
    display: 'inline',
    '> .MuiTypographyColorTextSecondary': {
      color: '#000',
    },
  },
  iconExchange: {
    transform: 'rotate(90deg)',
    fontSize: 8,
  },
  inputButtonsWrapper: {
    width: '100%',
    marginBottom: 20,
  },
  defaultInput: {
    width: '100%',
    marginTop: '0px !important',
    marginBottom: '20px !important',
    border: `1px solid ${COLORS.INPUT.BORDER}`,
    '& .MuiInputBase-input': {
      height: 25,
      fontSize: 16,
      lineHeight: '23px',
      color: COLORS.PRIMARY,
      fontFamily: 'Noto Sans',
      padding: '13px 20px !important',
      '&.Mui-disabled': {
        background: COLORS.INPUT.DISABLED.BACKGROUND,
      },
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${COLORS.INPUT.BORDER}`,
      borderColor: `${COLORS.INPUT.BORDER} !important`,
    },
    [`& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline`]: {
      border: `2px solid ${COLORS.SECONDARY}`,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderRadius: 5,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 1,
      marginBottom: 1,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '20px !important',
    },
  },
  endAdornment: {
    '& .MuiTypography-root': {
      color: COLORS.GREY,
      fontSize: 16,
    },
  },
  checkboxLabel: {
    fontSize: '14px',
    fontFamily: 'Noto Sans',
  },
  checkboxContainer: {
    width: '100%',
    marginLeft: 4,
    marginBottom: 11,
    marginTop: -10,

    '& .MuiFormControlLabel-root': {
      marginRight: 'unset',
    },
    '& .MuiSvgIcon-root path': {
      fill: 'transparent',
    },
  },
});

export default useStyles;
