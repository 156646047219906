import React, { useRef, useState } from 'react';
import { Typography } from '@mui/material';

import { Button, ButtonDisplayVariant } from 'components/button/button';
import { SignatureCanvas, SignatureInput } from 'components/inputs/signature/signature';
import Loading from 'components/loading/loading';
import PageHeader from 'components/pageHeader/pageHeader';

import LABELS from '../keys';
import useStyles from './styles';

interface SigningPageProps {
  signDonationPledge: () => void;
  goBack: () => void;
  updateSignature: (value: string) => void;
  isSubmitDisabled: boolean;
  submitting: boolean;
}

export const SigningPage = ({
  signDonationPledge,
  goBack,
  updateSignature,
  isSubmitDisabled,
  submitting,
}: SigningPageProps) => {
  const { classes } = useStyles();
  const [isNoticeVisible, setNoticeVisible] = useState<boolean>(Boolean(window?.tgbConfig?.transferNoticeEnabled));
  const [showFullAgreement, setShowFullAgreement] = useState<boolean>(false);
  const signatureFieldRef = useRef<SignatureCanvas | null>(null);

  const handleSignatureUpdate = (value: string) => {
    updateSignature(value);
  };

  const toggleAgreement = () => {
    setShowFullAgreement(!showFullAgreement);
  };

  const handleSignatureClear = () => {
    signatureFieldRef.current?.clear();
    updateSignature('');
  };

  const handleSignatureInputRef = (ref: SignatureCanvas | null) => {
    signatureFieldRef.current = ref;
  };

  const skipToSignature = () => {
    setNoticeVisible(false);
  };

  if (submitting) {
    return (
      <div className={classes.page}>
        <Loading text={LABELS.SIGNING} />
      </div>
    );
  }

  if (isNoticeVisible) {
    return (
      <div className={classes.page}>
        <PageHeader
          label={LABELS.TRANSFER_NOTICE_TITLE}
          onGoBack={goBack}
          withBackButton
        />
        <Typography className={classes.transferNotice}>
          {LABELS.TRANSFER_NOTICE_TEXT}
        </Typography>
        <div className={classes.buttons}>
          <Button
            onClick={skipToSignature}
            display={ButtonDisplayVariant.primary}
          >
            {LABELS.NEXT_BUTTON_LABEL}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <PageHeader
        label={LABELS.PAGE_TITLE}
        onGoBack={goBack}
        withBackButton
      />
      <div className={classes.signatureField}>
        <SignatureInput
          onChange={handleSignatureUpdate}
          inputRef={handleSignatureInputRef}
          width={370}
          height={200}
          hideClearButton
          hidePlaceholder={!isSubmitDisabled}
        />
      </div>
      <Typography className={classes.agreement}>
        {showFullAgreement ? LABELS.SIGNATURE_AGREEMENT : LABELS.SIGNATURE_AGREEMENT_SHORT}
        <button type="button" onClick={toggleAgreement} className={classes.showMore}>
          {showFullAgreement ? LABELS.SHOW_LESS : LABELS.SHOW_MORE}
        </button>
      </Typography>
      <div className={classes.buttons}>
        <Button
          display={ButtonDisplayVariant.secondary}
          onClick={handleSignatureClear}
        >
          {LABELS.CLEAR_BUTTON_LABEL}
        </Button>
        <Button
          onClick={signDonationPledge}
          disabled={isSubmitDisabled}
          display={ButtonDisplayVariant.primary}
          className={classes.nextButton}
        >
          {LABELS.NEXT_BUTTON_LABEL}
        </Button>
      </div>
    </div>
  );
};

export default SigningPage;
