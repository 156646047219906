import React from 'react';
import { Paper, Typography } from '@mui/material';

import tgbLogo from 'assets/images/tgb-logo.png';

import { useStyles } from './disabled.styles';

const KEYS = {
  DISABLED_PROMPT: 'Donations for {org} are unavailable at this moment.',
  TGB_LOGO_ALT: 'The Giving Block',
};

export interface DisabledPageProps {
  organizationName: string;
}

export const DisabledPage = ({ organizationName }: DisabledPageProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Paper elevation={3}>
        <div className={classes.contentContainer}>
          <div className={classes.header} />
          <div className={classes.content}>
            <img src={tgbLogo} className={classes.logo} alt={KEYS.TGB_LOGO_ALT} />
            <Typography className={classes.prompt}>
              {KEYS.DISABLED_PROMPT.replace('{org}', organizationName)}
            </Typography>
          </div>
          <div className={classes.footer} />
        </div>
      </Paper>
    </div>
  );
};

export default DisabledPage;
