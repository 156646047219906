import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useField } from 'formik';

import { SetFieldValueFunction } from '../types';
import { AUTOCOMPLETE_ACTIONS, KEYS } from './fields.keys';
import { SelectOption } from './fields.types';
import { useStyles } from './styles';

interface AutocompleteFieldProps {
  item: any;
  values: any;
  disabled: boolean;
  isSubmitting: boolean;
  setFieldValue: SetFieldValueFunction;
  setFieldTouched: (name: string, touched: boolean) => void;
}

export const AutocompleteField = ({
  item,
  values,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
}: AutocompleteFieldProps) => {
  const [error, setError] = useState(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [, meta] = useField({ name: item.name });
  const hasError = Boolean((isSubmitting || meta.touched) && meta.error);
  const shouldShowError = !item.disabled && hasError;
  const { classes, cx } = useStyles({ isOpen, withOptions: Boolean(item?.options?.length) });
  const fieldName = item?.name;
  const idFieldName = `${fieldName}Id`;
  const currentValue = values[fieldName] || '';
  const canValidate = !item.isDisabled || isSubmitting;
  const shouldValidate = canValidate && item.isRequired;

  const getOptionLabel = (option: SelectOption | string) => (option as SelectOption)?.label
    || (option as string)
    || '';

  const onChangeOption = (_, option, action) => {
    setFieldTouched(fieldName, true);
    const optionId = option?.id;
    const optionLabel = option?.label;
    setFieldValue(fieldName, optionLabel, true);
    setFieldValue(idFieldName, optionId, false);

    const isInvalid = shouldValidate && action === AUTOCOMPLETE_ACTIONS.CLEAR;
    setError(isInvalid);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onTypeOption = (event, value: string) => {
    const eventIsNull = event === null;
    const valueIsUnchanged = value === values[fieldName];

    if (valueIsUnchanged || eventIsNull) {
      return;
    }

    const isInvalid = shouldValidate && value.length === 0;
    setFieldValue(fieldName, value, true);
    setFieldTouched(fieldName, true);
    setError(isInvalid);
  };

  const renderInput = (params) => {
    const hasError = !params.disabled
      && (error || shouldShowError);
    const label = `${item.label}${item.isRequired ? KEYS.REQUIRED_ASTERISK : ''}`;

    return (
      <FormControl fullWidth>
        <TextField
          {...params}
          classes={{
            root: classes.autocompleteInput,
          }}
          margin="dense"
          required={item.isRequired}
          placeholder={label}
          error={hasError}
        />
        <FormHelperText>{hasError && meta.error}</FormHelperText>
      </FormControl>
    );
  };

  useEffect(() => {
    if (!item.isDisabled) {
      setError(false);
    }
  }, [values, item.isDisabled]);

  useEffect(() => {
    setError(item.error);
  }, [item.error]);

  return (
    <div className={cx([item.view === 'short' ? '' : classes.longView, classes.formItem])}>
      <Autocomplete
        disabled={item.isDisabled}
        freeSolo
        openOnFocus
        classes={{
          inputRoot: classes.autocompleteInputRoot,
          option: classes.option,
          popper: classes.listPopper,
          listbox: classes.listBox,
        }}
        blurOnSelect
        onOpen={handleOpen}
        onClose={handleClose}
        options={item.options}
        getOptionLabel={getOptionLabel}
        onChange={onChangeOption}
        onInputChange={onTypeOption}
        renderInput={renderInput}
        inputValue={currentValue}
      />
    </div>
  );
};

export default AutocompleteField;
