import uniq from 'lodash/uniq';
import qs from 'query-string';

import { OrganizationInfo } from 'types/organization';
import { DesignVersion, DonationWorkflowType } from 'types/workflow';

export const getSpecifiedFlows = () => {
  const { search } = window.location;
  const {
    donationFlow,
  } = qs.parse(search, { arrayFormat: 'comma' });

  return donationFlow as DonationWorkflowType | DonationWorkflowType[];
};

export const hasMultipleFlowsEnabled = (organizationInfo: OrganizationInfo) => getAvailableFlows(organizationInfo).length > 1;

export const getAvailableFlows = (organizationInfo: OrganizationInfo) => {
  const isChariotEnabled = window?.tgbConfig?.chariot?.enabled ?? false;
  const areDafDonationsEnabled = organizationInfo.areDafDonationsEnabled && isChariotEnabled;

  const {
    areFiatDonationsEnabled,
    areCryptoDonationsEnabled,
    areStockDonationsEnabled,
    areACHDonationsEnabled,
  } = organizationInfo;

  const flowAvailabilityValues = [
    areCryptoDonationsEnabled,
    areFiatDonationsEnabled,
    areStockDonationsEnabled,
    areDafDonationsEnabled,
    areACHDonationsEnabled,
  ];

  const workflowTypes = [
    DonationWorkflowType.Crypto,
    DonationWorkflowType.CreditCard,
    DonationWorkflowType.Stock,
    DonationWorkflowType.Daf,
    DonationWorkflowType.Ach,
  ];

  const availableFlows = workflowTypes.filter((_, index) => flowAvailabilityValues[index]);
  const specifiedFlows = getSpecifiedFlows();

  if (Array.isArray(specifiedFlows)) {
    return uniq(specifiedFlows.filter(specifiedFlow => availableFlows.includes(specifiedFlow)));
  }

  if (specifiedFlows && availableFlows.includes(specifiedFlows)) {
    return [specifiedFlows];
  }

  return availableFlows;
};

export const getInitialWorkflowType = (
  organizationInfo: OrganizationInfo,
  currentFlow: DonationWorkflowType,
  version?: DesignVersion,
) => {
  if (version === DesignVersion.V2) {
    return currentFlow;
  }

  const availableFlows = getAvailableFlows(organizationInfo);
  if (availableFlows.length !== 1) {
    return null;
  }

  const [workflow] = availableFlows;
  return workflow;
};

export default hasMultipleFlowsEnabled;
