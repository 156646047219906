import React, { useEffect } from 'react';
import { Tooltip } from '@mui/material';

import { BottomButtons } from 'components';
import Checkbox from 'components/checkbox';
import LoadingIcon from 'components/loading/loadingIcon';
import OptionallyVisible from 'components/optionallyVisible';
import { DonationPledge } from 'types/pledge';
import { USDFormatter } from 'utils/currency';

import { KEYS, LABELS } from './dafConfirm.keys';
import useStyles from './dafConfirm.styles';

export interface DafConfirmProps {
  amount: number;
  fetchedAmount: number;
  pledge: DonationPledge;
  isFinalizing: boolean;
  isFetchingFinalAmount: boolean;
  finalAmountErrror: Record<string, string>;
  goBack: () => void;
  updatePledge: (changes: Partial<DonationPledge>) => void;
  fetchDonationAmount: (coverTransactionFees: boolean) => void;
  postDonationInfo: () => void;
}

export const DafConfirm = ({
  amount,
  fetchedAmount,
  pledge,
  isFinalizing,
  isFetchingFinalAmount,
  finalAmountErrror,
  goBack,
  updatePledge,
  fetchDonationAmount,
  postDonationInfo,
}: DafConfirmProps) => {
  const { classes } = useStyles();
  const amountWithFeesInCents = (pledge.coverTransactionFees ? fetchedAmount : amount);
  const finalAmount = amountWithFeesInCents / 100;
  const formattedAmount = USDFormatter().format(finalAmount);

  useEffect(() => {
    fetchDonationAmount(true);
  }, []);

  const getChangeHandler = (field: string) => (value: boolean) => {
    updatePledge({ [field]: value } as Partial<DonationPledge>);
  };

  return (
    <div className={classes.pageWrapper}>
      <OptionallyVisible visible={isFetchingFinalAmount}>
        <LoadingIcon />
      </OptionallyVisible>
      <OptionallyVisible visible={!isFetchingFinalAmount}>
        <div className={classes.content}>
          <div className={classes.header}>
            <h3 className={classes.headerTitle}>
              {LABELS.PAGE_TITLE}
            </h3>
          </div>
          <div className={classes.checkboxContainer}>
            <Checkbox
              disabled={isFinalizing}
              onChange={getChangeHandler(KEYS.COVER_TRANSACTION_FEES)}
              value={pledge.coverTransactionFees}
              name={KEYS.COVER_TRANSACTION_FEES}
              label={(
                <div
                  className={classes.checkboxLabel}
                >
                  {LABELS.COVER_TRANSACTION_FEES}
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltip,
                    }}
                    title={LABELS.TRANSACTION_FEES_TOOLTIP}
                  >
                    <span className={classes.checkboxLabelTooltip}>(?)</span>
                  </Tooltip>
                </div>
              )}
            />
            <Checkbox
              disabled={isFinalizing}
              onChange={getChangeHandler(KEYS.ARE_NOTES_ENABLED)}
              value={pledge.areNotesEnabled}
              name={KEYS.ARE_NOTES_ENABLED}
              label={(
                <div
                  className={classes.checkboxLabel}
                >
                  {LABELS.ENABLE_NOTES}
                </div>
              )}
            />
            <Checkbox
              disabled={isFinalizing}
              onChange={getChangeHandler(KEYS.ARE_TRIBUTES_ENABLED)}
              value={pledge.areTributesEnabled}
              name={KEYS.ARE_TRIBUTES_ENABLED}
              label={(
                <div
                  className={classes.checkboxLabel}
                >
                  {LABELS.ENABLE_TRIBUTE}
                </div>
              )}
            />
          </div>
          <div className={classes.donationAmountContainer}>
            <h3 className={classes.donationAmountTitle}>
              {LABELS.DONATION_AMOUNT_TITLE}
            </h3>
            <div className={classes.donationAmount}>
              {formattedAmount}
            </div>
            <OptionallyVisible visible={Boolean(finalAmountErrror)}>
              <div>
                {finalAmountErrror.errorMessage}
              </div>
            </OptionallyVisible>
          </div>
        </div>
      </OptionallyVisible>
      <BottomButtons
        isDisabledSubmit={isFetchingFinalAmount}
        onClickLeft={goBack}
        onClickRight={postDonationInfo}
        rightButtonText={LABELS.RIGHT_BUTTON_TEXT}
        leftButtonText={LABELS.LEFT_BUTTON_TEXT}
        rightButtonLoading={isFinalizing}
      />
    </div>
  );
};

export default DafConfirm;
