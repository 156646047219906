import { BASIC_CURRENCIES } from 'constants/currencies';
import { ApplePayEventAction, TGB_WIDGET_CHANNEL } from 'types/applePay';

import { GetCardTokenParams } from '../getCardToken.thunk';

const KEYS = {
  BUTTON_ID: 'apple-pay-submit-button',
  SUPPORTED_METHODS: 'https://apple.com/apple-pay',
  APPLE_PAY_RESPONSE_DEFAULT_ERROR_MESSAGE: 'Unable to get Apple Pay response',
  UNSPECIFIED_ORIGIN: '*',
  SUPPORTED_NETWORKS: [
    'amex',
    'masterCard',
    'visa',
  ],
};

export const payWithApplePay = async ({
  merchantName,
  donationAmount,
  countryCode,
  shift4PublicKey,
  widgetId,
}: GetCardTokenParams) => {
  const applePayMethodData = {
    supportedMethods: KEYS.SUPPORTED_METHODS,
    data: {
      countryCode,
      supportedNetworks: KEYS.SUPPORTED_NETWORKS,
    },
  };
  const shoppingCartDetails = {
    total: {
      label: merchantName,
      amount: {
        currency: BASIC_CURRENCIES.USD,
        value: donationAmount,
      },
    },
  };

  const token = await new Promise<Record<string, string> | null>((resolve, reject) => {
    const handleEvent = (event) => {
      const isTgbChannel = event.data.channel === TGB_WIDGET_CHANNEL;
      const isPaymentRequest = event.data.action === ApplePayEventAction.createApplePayPaymentRequest;
      const hasPaymentResponseDetails = Boolean(event.data?.paymentResponse?.details);

      const isValidPaymentRequest = [isTgbChannel, isPaymentRequest, hasPaymentResponseDetails].every(Boolean);

      try {
        if (isValidPaymentRequest) {
          window.removeEventListener('message', handleEvent);
          resolve(event.data.paymentResponse.details.token.paymentData);
          return;
        }

        if (event.data.error) {
          reject(event.data.error);
        }
      } catch (e) {
        const error = e ?? new Error(KEYS.APPLE_PAY_RESPONSE_DEFAULT_ERROR_MESSAGE);
        reject(error);
      }
    };

    window.addEventListener('message', handleEvent);

    window.parent.postMessage({
      channel: TGB_WIDGET_CHANNEL,
      action: ApplePayEventAction.createApplePayPaymentRequest,
      applePayMethodData,
      shoppingCartDetails,
      shift4PublicKey,
      widgetId,
    }, KEYS.UNSPECIFIED_ORIGIN);
  });

  return token;
};

export default payWithApplePay;
