import { connect } from 'react-redux';
import qs from 'query-string';
import { Dispatch } from 'redux';

import { DEFAULT_USD_VALUES } from 'config/workflow';
import { BASIC_CURRENCIES } from 'constants/currencies';
import fetchCurrencies from 'state/currencies/thunk/fetchCurrencies.thunk';
import setFundraiserId from 'state/fundraiser/setFundraiserId';
import fetchOrganizationInfo from 'state/organization/thunk/fetchInitializationData.thunk';
import { InitializationDataResponse } from 'state/organization/thunk/fetchInitializationData.types';
import changeFiatAmount from 'state/pledge/changeFiatAmount';
import fetchCurrencyExchangeRateSuccess from 'state/pledge/fetchCurrencyExchangeRate.success';
import updatePledge from 'state/pledge/updatePledge';
import { AppReduxState } from 'state/state';
import setWidgetId from 'state/widget/setWidgetId';
import setType from 'state/workflow/setType';
import { FundraiserId } from 'types/fundraiser';
import { WidgetInitializationParameters } from 'types/init';
import { DesignVersion, DonationWorkflowType, WidgetVariant } from 'types/workflow';
import { getUsedDesignVersion } from 'utils/componentSwitch';
import detectDocumentOrigin from 'utils/detectDocumentOrigin';
import { getAvailableFlows, getSpecifiedFlows } from 'utils/organization';
import { getStringParam, removeEmptyQueryParamEntries } from 'utils/queryParams';
import checkIsFiatDonationType from 'utils/workflow';

import { StartScreen } from './start';
import LABELS from './start.keys';

interface DefaultStateParams {
  areFeesCoveredByDefaultEnabled: boolean;
  showFrequencyStep: boolean;
  defaultAmount?: number | null;
  defaultCryptocurrency?: string | null;
}

const mapStateToProps = (state: AppReduxState) => {
  const {
    organization,
    isFetching,
    error,
  } = state.organization;

  const { defaultAmount, defaultCryptocurrency } = state.donationData;

  const { search } = window.location;
  const {
    charityID,
    display,
    fundraiserId,
    scriptId,
    campaignId,
    donationDataId,
  } = qs.parse(search);

  const { areRecurringDonationsEnabled, areFeesCoveredByDefaultEnabled } = organization;

  const id = charityID?.toString() || '';

  const fid = fundraiserId?.toString() || null;
  const availableFlows = getAvailableFlows(organization);

  return {
    organization,
    isFetching,
    display,
    error,
    id,
    availableFlows,
    fundraiserId: fid,
    areRecurringDonationsEnabled,
    areFeesCoveredByDefaultEnabled,
    scriptId,
    campaignId,
    defaultAmount,
    defaultCryptocurrency,
    donationDataId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  initialize: async (fundraiserId: FundraiserId, scriptId: string) => {
    dispatch(setFundraiserId.createAction(fundraiserId));
    dispatch(setWidgetId.createAction(scriptId));
    await dispatch(fetchCurrencies());
  },
  fetchOrganizationInfo: (params: WidgetInitializationParameters) => dispatch(fetchOrganizationInfo(params)),
  setType: (
    type: DonationWorkflowType | null,
    {
      areFeesCoveredByDefaultEnabled,
      showFrequencyStep,
      defaultAmount,
      defaultCryptocurrency,
    }: DefaultStateParams,
  ) => {
    // TODO: Refactor setType, export this logic in a single place, as it is used on multiple occasions TGB-8261
    const isFiatDonationType = checkIsFiatDonationType(type);
    if (isFiatDonationType) {
      dispatch(fetchCurrencyExchangeRateSuccess.createAction(1));
      dispatch(updatePledge.createAction({
        coverTransactionFees: areFeesCoveredByDefaultEnabled,
        currency: BASIC_CURRENCIES.USD,
      }));
      dispatch(changeFiatAmount.createAction(DEFAULT_USD_VALUES.FIAT));
    } else {
      dispatch(updatePledge.createAction({
        coverTransactionFees: areFeesCoveredByDefaultEnabled,
        currency: defaultCryptocurrency ?? BASIC_CURRENCIES.BTC,
      }));
      dispatch(changeFiatAmount.createAction(defaultAmount ?? DEFAULT_USD_VALUES.CRYPTO));
    }
    dispatch(setType.createAction(type, showFrequencyStep));
  },
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => ({
  ...stateProps,
  ...dispatchProps,
  initialize: async (id: string) => {
    const designVersion = getUsedDesignVersion();
    const origin = await detectDocumentOrigin ?? LABELS.UNKNOWN;
    const scriptId = removeEmptyQueryParamEntries(stateProps.scriptId)?.toString() ?? LABELS.UNKNOWN;
    const display = removeEmptyQueryParamEntries(stateProps.display);
    const donationDataId = getStringParam(stateProps.donationDataId);
    const params: WidgetInitializationParameters = {
      organizationId: id,
      origin,
      scriptId,
      donationDataId,
      options: {
        display: display as WidgetVariant,
        version: designVersion,
        fundraiserId: stateProps.fundraiserId,
        workflow: getSpecifiedFlows(),
      },
    };

    await dispatchProps.initialize(
      stateProps.fundraiserId,
      scriptId,
    );
    const responseData = await dispatchProps.fetchOrganizationInfo(params) as never as InitializationDataResponse;

    if (!responseData) {
      return;
    }
    const { donationData, ...organizationInfo } = responseData;

    const { areRecurringDonationsEnabled, areFeesCoveredByDefaultEnabled = false } = organizationInfo;
    const isV2Design = designVersion === DesignVersion.V2;
    const availableFlows = getAvailableFlows(organizationInfo);
    const shouldSetDonationFlow = availableFlows.length === 1 || isV2Design;

    if (shouldSetDonationFlow) {
      const isCardDonationAvailable = availableFlows.includes(DonationWorkflowType.CreditCard);
      const shouldSetCardFlow = isV2Design && stateProps.fundraiserId && isCardDonationAvailable;
      const nextDonationFlow = shouldSetCardFlow ? DonationWorkflowType.CreditCard : availableFlows[0];
      dispatchProps.setType(
        nextDonationFlow,
        {
          areFeesCoveredByDefaultEnabled,
          showFrequencyStep: areRecurringDonationsEnabled,
          defaultAmount: donationData?.defaultAmount,
          defaultCryptocurrency: donationData?.defaultCryptocurrency?.toLowerCase(),
        },
      );
    }
  },
  setType: (type: DonationWorkflowType | null) => {
    dispatchProps.setType(
      type,
      {
        areFeesCoveredByDefaultEnabled: stateProps.areFeesCoveredByDefaultEnabled,
        showFrequencyStep: stateProps.areRecurringDonationsEnabled,
        defaultAmount: stateProps?.defaultAmount,
        defaultCryptocurrency: stateProps?.defaultCryptocurrency?.toLowerCase(),
      },
    );
  },
});

export const StartConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(StartScreen);

export default StartConnect;
