import React, { useEffect } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';

import loadingLogo from 'assets/images/simple-tgb-logo.png';
import { Button } from 'components/button/button';
import PageHeader from 'components/pageHeader/pageHeader';
import { Broker, BrokerInfo } from 'types/broker';

import { LABELS } from './brokerInfo.keys';
import { BrokerInforForm } from './brokerInfoForm';
import { useStyles } from './styles';

interface OverlayBrokerInfoScreenProps {
  brokerInfo: BrokerInfo;
  updateBrokerInfo: (changes: Partial<BrokerInfo>) => void;
  fetchStockBrokers: () => void;
  brokers: Broker[];
  submitBrokerInfo: () => void;
  isDisabledSubmit: boolean;
  goBack: () => void;
  isSubmitting: boolean;
  error: Record<string, string>;
}

const OverlayBrokerInfoScreen = ({
  brokerInfo,
  updateBrokerInfo,
  fetchStockBrokers,
  brokers,
  submitBrokerInfo,
  isDisabledSubmit,
  goBack,
  isSubmitting,
  error,
}: OverlayBrokerInfoScreenProps) => {
  const { classes } = useStyles();
  useEffect(() => {
    fetchStockBrokers();
  }, []);

  const handleSubmit = () => {
    submitBrokerInfo();
  };

  if (isSubmitting) {
    return (
      <div className={classes.scrollContainer}>
        <PageHeader label={LABELS.PAGE_TITLE} withBackButton onGoBack={goBack} />
        <div className={classes.loadingContainer}>
          <div className={classes.imageLoadingContainer}>
            <img src={loadingLogo} alt="processing" className={classes.loadingImage} />
          </div>
          <Typography variant="h6" className={classes.loadingText}>
            {LABELS.PROCESSING}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.scrollContainer}>
      <PageHeader label={LABELS.PAGE_TITLE} withBackButton onGoBack={goBack} />
      <BrokerInforForm
        brokerInfo={brokerInfo}
        onChange={updateBrokerInfo}
        brokers={brokers}
        error={error}
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Button
          onClick={handleSubmit}
          disabled={isDisabledSubmit || isSubmitting}
          className={classes.nextButton}
        >
          {LABELS.NEXT}
        </Button>
      </Box>
    </div>
  );
};

export default OverlayBrokerInfoScreen;
