import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Tooltip } from '@mui/material';
import qrCode from 'qrcode-generator';

import { BottomButtons, Button, Icon } from 'components';
import { IconCopy } from 'components/icon/copy';
import IconMetamask from 'components/icon/metamask';
import OptionallyVisible from 'components/optionallyVisible';
import COLORS from 'theme/colors';
import Currency from 'types/currency';
import { DonationCredentials } from 'types/donation';
import { DonationPledge } from 'types/pledge';
import useReturnUrl from 'utils/hooks/useReturnUrl';
import { getIsCurrencySuppored } from 'utils/metamask';

import Loading from '../../../components/loading';
import { FallbackWarningDialog } from './fallbackWarningDialog';
import {
  DONATION_NOTES,
  KEYS,
  LABELS,
  PLACEHOLDERS,
} from './keys';
import { MetamaskConnect } from './metamask.connect';
import useStyles from './styles';

interface DonateScreenProps {
  pledge: DonationPledge;
  donation: DonationCredentials;
  isSubmitting: boolean;
  currencies: Currency[];
  metamaskInstalled: boolean;
  isRipple: boolean;
  goBack: () => void;
  resetWidget: () => void;
  onInit: () => void;
  returnUrl: string | null;
  fetchCryptoDonationStatus: () => Promise<Boolean>;
}

const prepareDonationAddress = (address: string): string => {
  const isLongAddress = address.length > KEYS.LONG_ADDRESS_CHARACTERS_LENGTH;
  const truncatedAddress = [
    address.slice(0, KEYS.ADDRESS_CHOP_LENGTH),
    KEYS.LONG_ADDRESS_JOIN_SYMBOL,
    address.slice(-KEYS.ADDRESS_CHOP_LENGTH),
  ].join('');

  const output = isLongAddress ? truncatedAddress : address;

  return output;
};

export const DonateScreen = ({
  pledge,
  donation,
  isSubmitting,
  goBack,
  resetWidget,
  currencies,
  metamaskInstalled,
  onInit,
  isRipple,
  returnUrl,
  fetchCryptoDonationStatus,
}: DonateScreenProps) => {
  const { cx, classes } = useStyles();
  const [copied, setCopied] = useState(false);
  const [tagCopied, setTagCopied] = useState(false);
  const [metamaskScreenActive, setMetamaskScreenActive] = useState(false);
  const [isModalOpen, setModalOpen] = useState(true);
  const navigateToReturnUrl = useReturnUrl({
    returnUrl,
    fetchCryptoDonationStatus,
  });

  useEffect(() => {
    if (donation.donationAddress) {
      onInit();
    }
  }, [donation.donationAddress]);

  const iconSourceMap = useMemo(() => (
    currencies.reduce((acc, c) => ({
      ...acc,
      [c.code.toLowerCase()]: c.imageUrl,
    }), [])
  ), [currencies]);

  const [pledgedCurrency, isERC20] = useMemo(() => {
    const pledgedCurrency = currencies.find(({ code }) => code.toLowerCase() === pledge.currency);
    return [
      pledgedCurrency,
      Boolean(pledgedCurrency?.isErc20),
    ];
  }, [currencies, pledge]);

  const metamaskButtonVisible = useMemo(() => metamaskInstalled && getIsCurrencySuppored(pledge.currency, isERC20), []);

  const toggleMetamaskScreen = (value: boolean) => () => {
    setMetamaskScreenActive(value);
  };

  const getQR = (string, className) => {
    if (string) {
      const qrImage = qrCode(4, 'M');
      qrImage.addData(string);
      qrImage.make();

      return (
        <div className={classes.qrWrapper}>
          <img
            src={iconSourceMap[pledge.currency]}
            alt={pledge.currency}
            className={classes.iconWrapQR}
          />
          <img className={className} src={qrImage.createDataURL(4)} alt={KEYS.QR_ALT} />
        </div>
      );
    }
    return <Icon className={className} name="tgbLogo" />;
  };

  const fifteenMinutesLater = new Date();
  fifteenMinutesLater.setMinutes(fifteenMinutesLater.getMinutes() + 15);

  const toggleModalOpen = () => {
    setModalOpen(!isModalOpen);
  };

  const valueToGenerateQrCode = donation.qrValue ?? donation.donationAddress;
  const getPaymentQR = () => getQR(valueToGenerateQrCode, classes.qr);

  const shouldDisableFallbackWarningDialog = Boolean(!donation.fallbackAddressUsed && donation.donationAddress);
  const pledgedCrypto = pledge.currency.toUpperCase();

  if (isSubmitting) {
    return <Loading text={LABELS.LOADING_ADDRESS} />;
  }

  const isSPL = currencies.some(({ code, network }) => (
    code.toLowerCase() === pledge.currency
    && network === KEYS.SPL_NETWORK
  ));

  const cryptoAmountString = [
    pledge.amount,
    pledge.currency.toUpperCase(),
    isERC20 && KEYS.ERC_20,
    isSPL && KEYS.SPL_TOKEN,
  ].filter(Boolean).join(' ');

  const handleCopy = handlerFunc => () => {
    handlerFunc(true);
    setTimeout(() => {
      handlerFunc(false);
    }, 2000);
  };

  if (metamaskScreenActive) {
    return <MetamaskConnect goBack={toggleMetamaskScreen(false)} currency={pledgedCurrency} />;
  }

  const formattedDonationAddress = prepareDonationAddress(donation.donationAddress);
  const hasReturnUrl = Boolean(returnUrl);

  const rightButtonText = hasReturnUrl ? LABELS.COMPLETED_BUTTON_TEXT : LABELS.RIGHT_BUTTON_TEXT;
  const handleRightButtonClick = hasReturnUrl ? navigateToReturnUrl : resetWidget;
  const fontAwesomeIcon = hasReturnUrl ? 'circle-check' : 'sync';

  return (
    <>
      <Container className={classes.donationContainerWrapper}>
        <div className={classes.topContainer}>
          <div className={classes.topMessageContainer}>
            <div className={classes.amountText}>
              {LABELS.DONATE_PROPMT_1}
              <br />
              <b>
                {cryptoAmountString}
              </b>
              <Tooltip
                title={LABELS.DONATION_NOTE}
                classes={{
                  tooltip: classes.donationTooltip,
                }}
                enterTouchDelay={KEYS.TOOLTIP_TOUCH_DELAY}
              >
                <span className={classes.iconEyeTooltip}>
                  <FontAwesomeIcon icon="exclamation-circle" />
                </span>
              </Tooltip>
              &nbsp;
              {LABELS.DONATE_PROPMT_2}
            </div>
          </div>
          {getPaymentQR()}
        </div>
        <div className={classes.adresses}>
          <CopyToClipboard
            text={donation.donationAddress}
            onCopy={handleCopy(setCopied)}
          >
            <button
              type="button"
              className={classes.addressCopyButton}
            >
              <OptionallyVisible visible={copied}>
                <span>{LABELS.COPIED}</span>
              </OptionallyVisible>
              <OptionallyVisible visible={!copied}>
                <span>{formattedDonationAddress}</span>
              </OptionallyVisible>
              <span className={classes.iconCopyWrapper}>
                <IconCopy width={15} height={15} color={COLORS.PRIMARY} />
              </span>
            </button>
          </CopyToClipboard>
          <OptionallyVisible visible={isRipple}>
            <div>
              <div className={classes.tagLabel}>{LABELS.DESTINATION_TAG}</div>
              <CopyToClipboard
                text={donation.destinationTag}
                onCopy={handleCopy(setTagCopied)}
              >
                <button
                  type="button"
                  className={classes.addressCopyButton}
                >
                  <OptionallyVisible visible={tagCopied}>
                    <span>{LABELS.COPIED}</span>
                  </OptionallyVisible>
                  <OptionallyVisible visible={!tagCopied}>
                    <span>{donation.destinationTag}</span>
                  </OptionallyVisible>
                  <span className={classes.iconCopyWrapper}>
                    <IconCopy width={15} height={15} color={COLORS.PRIMARY} />
                  </span>
                </button>
              </CopyToClipboard>
            </div>
          </OptionallyVisible>
        </div>
        <OptionallyVisible visible={metamaskButtonVisible}>
          <div>
            <Button className={classes.metamaskButton} onClick={toggleMetamaskScreen(true)}>
              <IconMetamask className={classes.walletButtonIcon} width={15} height={15} />
              {LABELS.DONATE_WITH_METAMASK}
            </Button>
          </div>
        </OptionallyVisible>
        <div>
          <OptionallyVisible visible={isRipple}>
            <p className={classes.donationNotes}>
              {LABELS.XRP_DONATION_NOTE}
            </p>
          </OptionallyVisible>
          <p className={classes.donationNotes}>
            {DONATION_NOTES[pledgedCrypto] || DONATION_NOTES.DEFAULT
              .replace(PLACEHOLDERS.PLEDGED_CRYPTO, pledgedCrypto)}
          </p>
        </div>
        <FallbackWarningDialog
          open={isModalOpen}
          toggleOpen={toggleModalOpen}
          disable={shouldDisableFallbackWarningDialog}
        />
        <OptionallyVisible visible={hasReturnUrl}>
          <div className={cx(classes.returnUrlMessage, {
            [classes.additionalMarginReturnUrlMessage]: !isRipple,
          })}
          >
            {LABELS.CLICK_WHEN_READY}
          </div>
        </OptionallyVisible>
      </Container>
      <BottomButtons
        rightIcon={['fas', fontAwesomeIcon]}
        isDisabledSubmit={false}
        onClickLeft={goBack}
        onClickRight={handleRightButtonClick}
        rightButtonText={rightButtonText}
        leftButtonText={LABELS.LEFT_BUTTON_TEXT}
      />
    </>
  );
};

export default DonateScreen;
