import facebookLogo from 'assets/images/facebook.svg';
import linkedinLogo from 'assets/images/linkedin.svg';
import twitterLogo from 'assets/images/twitter.svg';

export const LABELS = {
  PAGE_HEADER_TEXT: 'Thank you!',
  PAGE_SUB_HEADER_TEXT: 'Every donation makes a difference.',
  PROCEED_BUTTON_TEXT: 'Donate Again',
  ALT: {
    GRATITUDE: 'Thank You!',
    INSTRUCTIONS_1: '',
    INSTRUCTIONS_2: '',
    HEADER: 'Thank You!',
  },
  SHARE_HEADER: 'Help spread the word by sharing!',
  SHARE_MESSAGE: '1 out of 4 people you share this with are likely to donate',
};

export const KEYS = {
  BACKGROUND_ALT: 'background',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  OTHER_BROKER: 'other',
  TWITTER_DONATION_MESSAGE: (organizationName: string) => `I just made a donation to ${
    organizationName
  } using @TheGivingBlock Check them out here - ${DEFAULT_WEBSITE_LINK}`,
};

export const DEFAULT_WEBSITE_LINK = 'https://thegivingblock.com';

export const getLinkButtons = (organizationName?: string) => {
  if (!organizationName) {
    return [];
  }

  return [
    {
      key: KEYS.LINKEDIN,
      icon: linkedinLogo,
      linkDescription: 'Share on Linkedin',
      link: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(DEFAULT_WEBSITE_LINK)}`,
      backgroundColor: '#0077B5',
      iconWidth: 22,
    },
    {
      key: KEYS.FACEBOOK,
      icon: facebookLogo,
      linkDescription: 'Share on Facebook',
      link: `https://www.facebook.com/sharer.php?u=${encodeURIComponent(DEFAULT_WEBSITE_LINK)}`,
      backgroundColor: '#1877F2',
      iconWidth: 11,
    },
    {
      key: KEYS.TWITTER,
      icon: twitterLogo,
      linkDescription: 'Share on X',
      link: `https://twitter.com/intent/tweet?text=${encodeURIComponent(KEYS.TWITTER_DONATION_MESSAGE(
        organizationName,
      ))}`,
      backgroundColor: '#03A9F4',
    },
  ];
};

export default LABELS;
