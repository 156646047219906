import React, { ReactNode, useMemo } from 'react';
import { Paper } from '@mui/material';

import OptionallyVisible from 'components/optionallyVisible';
import { OrganizationInfo } from 'types/organization';
import { DonationWorkflowType, WorkflowStep } from 'types/workflow';

import { DisabledPage } from './disabled';
import { Header } from './header';
import { KEYS, LABELS } from './keys';
import { PoweredBy } from './poweredBy';
import Stages from './stages';
import useStyles from './styles';
import { PoweredByBrand } from './types';
import { getBrand } from './utils';

interface LayoutProps {
  children: ReactNode;
  organization: OrganizationInfo;
  step: WorkflowStep | null;
  workflowType: DonationWorkflowType;
  steps: {
    title: string;
    key: WorkflowStep;
  }[];
}

export const Layout = ({
  children,
  organization,
  step,
  steps,
  workflowType,
}: LayoutProps) => {
  const { classes } = useStyles();

  const hasSteps = step !== null;

  const brand: PoweredByBrand = useMemo(() => getBrand(workflowType), [workflowType]);

  if (organization?.disabled) {
    return <DisabledPage organizationName={organization.name} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.rootLogos}>
        <img src={KEYS.LOGO[PoweredByBrand.TGB]} alt={LABELS.ALT[PoweredByBrand.TGB]} />
        <div className={classes.rootLogosSeparator} />
        <img src={KEYS.LOGO[PoweredByBrand.Shift4]} alt={LABELS.ALT[PoweredByBrand.Shift4]} />
      </div>
      <div className={classes.container}>
        <Paper elevation={3} className={classes.paper}>
          <Header organization={organization} />
          <div className={classes.contentWrapper}>
            <OptionallyVisible visible={hasSteps}>
              <Stages
                stage={step as WorkflowStep}
                steps={steps}
              />
            </OptionallyVisible>
            {children}
          </div>
        </Paper>
      </div>
      <PoweredBy brand={brand} />
    </div>
  );
};

export default Layout;
