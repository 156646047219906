import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  checkedIcon: {
    background: 'linear-gradient(135deg, #FCD42B 0%, rgba(252, 212, 43, 0) 100%)',
    borderRadius: 5,
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      background: 'linear-gradient(135deg, #D5DDE3 0%, rgba(213, 221, 227, 0) 100%)',

      borderRadius: 5,
      width: 20,
      height: 20,

      '& path': {
        fill: 'transparent',
      },
    },
  },
});

export default useStyles;
