// @ts-ignore
import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import { DEFAULT_USD_VALUES } from 'config/workflow';
import { BASIC_CURRENCIES } from 'constants/currencies';
import setError from 'state/workflow/setError';
import { WidgetInitializationParameters } from 'types/init';

import setDonationData from '../../donationData/setDonationData';
import fetchOrganizationInfoFail from '../fetchOrganizationInfo.fail';
import fetchOrganizationInfoStart from '../fetchOrganizationInfo.start';
import fetchOrganizationInfoSuccess from '../fetchOrganizationInfo.success';
import { InitializationDataResponse } from './fetchInitializationData.types';

interface FetchOrganizationResponse {
  data: InitializationDataResponse;
}

const DEFAULT_ERROR = {
  errorCode: 'UNKNOWN_ERROR',
};

const ERRORS = {
  NOT_FOUND: 'Organization not found',
  UNKNOWN_ERROR: 'We were unable to load organization widget. Please try again later or contact us about this issue',
};

export const fetchOrganizationInfo = (
  params: WidgetInitializationParameters,
) => async (dispatch: Dispatch): Promise<InitializationDataResponse | { errorCode: string }> => {
  try {
    dispatch(fetchOrganizationInfoStart.createAction());

    const response: FetchOrganizationResponse = await API.post(Endpoints.initialize, params);

    if (response?.data) {
      const { donationData, ...organizationInfo } = response.data;

      const defaultAmount = donationData?.defaultAmount ?? DEFAULT_USD_VALUES.CRYPTO;
      const defaultCryptocurrency = donationData?.defaultCryptocurrency?.toLowerCase() ?? BASIC_CURRENCIES.BTC;
      const nextDonationData = { ...donationData, defaultAmount, defaultCryptocurrency };
      dispatch(setDonationData.createAction(nextDonationData));
      dispatch(fetchOrganizationInfoSuccess.createAction(organizationInfo));
      return response.data;
    }

    dispatch(fetchOrganizationInfoFail.createAction(DEFAULT_ERROR));
    dispatch(setError.createAction(ERRORS.NOT_FOUND));
    return DEFAULT_ERROR;
  } catch (e: any) {
    dispatch(fetchOrganizationInfoFail.createAction(e));
    dispatch(setError.createAction(ERRORS.UNKNOWN_ERROR));
    return e;
  }
};

export default fetchOrganizationInfo;
