import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import ValidationError from 'api/errors/validationError';
import setPledgeError from 'state/pledge/setError';
import changeStep from 'state/workflow/changeStep';
import setError from 'state/workflow/setError';
import { DonorInfo } from 'types/donor';
import { FundraiserId } from 'types/fundraiser';
import { OrganizationInfo } from 'types/organization';
import { DonationPledge } from 'types/pledge';
import { CreditCardDonationWorkflowStep, DonationWorkflowType } from 'types/workflow';
import { calculateWithFeesBeforeGross } from 'utils/currency';
import detectDocumentOrigin from 'utils/detectDocumentOrigin';
import { pushDataLayerEvent } from 'utils/gtm';
import GTMEventType from 'utils/gtm/types';
import eventDataFactory from 'utils/gtm/util';
import removeNullValues from 'utils/objects/removeNullValues';
import getApiUserUuid from 'utils/queryParams';

import createPaymentIntentFail from '../createPaymentIntentAndSubmitPledge.fail';
import createPaymentIntentStart from '../createPaymentIntentAndSubmitPledge.start';
import createPaymentIntentSuccess from '../createPaymentIntentAndSubmitPledge.success';

export const DEFAULT_ERROR_MESSGAE = 'Something went wrong. Please try again later.';

interface SubmitPledgeParams {
  organization: OrganizationInfo;
  pledge: DonationPledge;
  donor: DonorInfo;
  fundraiserId: FundraiserId;
  externalId?: string | null;
}

const preparePledge = ({
  pledge,
  organization,
  donor,
  documentOrigin,
  fundraiserId,
  apiUserUuid,
  externalId,
}) => removeNullValues({
  'Address 1': donor.address1 || '',
  'Address 2': donor.address2 || '',
  Anonim: donor.isAnonymous,
  City: donor.city || '',
  Country: donor.country || '',
  Email: donor.email || '',
  'First name': donor.firstName || '',
  'Last name': donor.lastName || '',
  State: donor.state || '',
  Zipcode: donor.zipCode || '',
  Notes: pledge.notes,
  charityID: organization.id,
  origin: documentOrigin,
  campaignId: pledge.campaignId || null,
  pledgeAmount: pledge.coverTransactionFees
    ? calculateWithFeesBeforeGross(pledge.usdAmount, organization.fiatFee)
    : pledge.usdAmount,
  pledgeCurrency: pledge.currency,
  coveredTransactionFees: pledge.coverTransactionFees,
  fundsDesignation: pledge.fundsDesignation || undefined,
  fundraiserId,
  receiptEmail: donor.receiptEmail || '',
  apiUserUuid,
  honoreeEmail: pledge.notifyHonoree ? pledge.honoreeEmail : null,
  honoreeName: pledge.honoreeName,
  externalId,
  isCharityCommunicationAllowed: donor.isCharityCommunicationAllowed,
});

export const createPaymentIntentAndPledge = ({
  organization,
  pledge,
  donor,
  fundraiserId,
  externalId,
}: SubmitPledgeParams) => async (dispatch: Dispatch<any>, getState) => {
  dispatch(createPaymentIntentStart.createAction());

  const documentOrigin = await detectDocumentOrigin;
  const donorInfo = donor.isAnonymous ? { isAnonymous: true, receiptEmail: donor.receiptEmail } : donor;
  const apiUserUuid = getApiUserUuid();

  const preparedPledge = preparePledge({
    donor: donorInfo,
    organization,
    pledge,
    documentOrigin,
    fundraiserId,
    apiUserUuid,
    externalId,
  });

  try {
    const response = await API.post(Endpoints.donationFiat, preparedPledge);

    if (response?.data) {
      const state = getState();
      pushDataLayerEvent(
        eventDataFactory(
          GTMEventType.TaxReceipt,
          DonationWorkflowType.CreditCard,
          { state, email: donor.receiptEmail || donor.email },
        ),
      );
      dispatch(createPaymentIntentSuccess.createAction(response.data));
      dispatch(changeStep.createAction(CreditCardDonationWorkflowStep.FillCardInfo));
      return;
    }

    dispatch(createPaymentIntentFail.createAction({
      errorMessage: DEFAULT_ERROR_MESSGAE,
    }));
    dispatch(changeStep.createAction(CreditCardDonationWorkflowStep.Error));
    dispatch(setError.createAction(DEFAULT_ERROR_MESSGAE));
  } catch (error: any) {
    const { message, details } = error;
    if (error instanceof ValidationError) {
      dispatch(createPaymentIntentFail.createAction({
        errorMessage: message,
      }));
      dispatch(setPledgeError.createAction(error.getValidationErrorDetails()));
      return;
    }

    const errorMessage = details?.errorMessage || message || DEFAULT_ERROR_MESSGAE;
    dispatch(createPaymentIntentFail.createAction({
      errorMessage,
    }));
    dispatch(changeStep.createAction(CreditCardDonationWorkflowStep.Error));
    dispatch(setError.createAction(errorMessage));
  }
};

export default createPaymentIntentAndPledge;
