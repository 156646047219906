import { DonorInfo } from 'types/donor';

import { AnyReduxState } from '../state.types';

export type DonorReduxState = AnyReduxState & DonorInfo & {
  countryId?: string;
  stateId?: string;
}

export const initialState = (): DonorReduxState => ({
  isAnonymous: false,
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  state: '',
  address1: '',
  address2: '',
  city: '',
  zipCode: '',
  phoneNumber: '',
  birthDate: undefined,
  isCharityCommunicationAllowed: false,
});
