import React, { ReactNode, useMemo } from 'react';
import { Paper } from '@mui/material';

import { OrganizationInfo } from 'types/organization';
import DonationWorkflowType, {
  CreditCardDonationWorkflowStep,
  CryptoDonationWorkflowStep,
  WidgetVariant,
} from 'types/workflow';
import { getUsedDisplayVariant } from 'utils/componentSwitch';

import { DisabledPage } from '../disabled';
import { KEYS, LABELS } from '../keys';
import { PoweredByBrand } from '../types';
import getBrand from '../utils';
import Footer from './footer';
import { Header } from './header';
import useStyles from './styles';

interface LayoutProps {
  children: ReactNode;
  organization: OrganizationInfo;
  step: CryptoDonationWorkflowStep | CreditCardDonationWorkflowStep | null;
  steps: {
    title: string;
    key: CryptoDonationWorkflowStep;
  }[];
  workflowType: DonationWorkflowType;
}

export const Layout = ({
  children,
  organization,
  step,
  steps,
  workflowType,
}: LayoutProps) => {
  const isOverlay = getUsedDisplayVariant() === WidgetVariant.Overlay;
  const { classes } = useStyles({ isOverlay });

  const brand: PoweredByBrand = useMemo(() => getBrand(workflowType), [workflowType]);

  if (organization?.disabled) {
    return <DisabledPage organizationName={organization.name} />;
  }

  return (
    <div className={classes.outerContainer}>
      <div className={classes.rootLogos}>
        <img src={KEYS.LOGO[PoweredByBrand.TGB]} alt={LABELS.ALT[PoweredByBrand.TGB]} />
        <div className={classes.rootLogosSeparator} />
        <img src={KEYS.LOGO[PoweredByBrand.Shift4]} alt={LABELS.ALT[PoweredByBrand.Shift4]} />
      </div>
      <div className={classes.innerContainer}>
        <Paper className={classes.paper}>
          <Header organization={organization} />
          <div className={classes.contentWrapper}>
            {children}
          </div>
        </Paper>
        <Footer brand={brand} />
      </div>
    </div>
  );
};

export default Layout;
