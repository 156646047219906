import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  icon: {
    maxWidth: 150,
  },
  title: {
    margin: 12,
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 25,
    lineHeight: '35px',
  },
  label: {
    fontFamily: 'Noto sans',
    fontWeight: 400,
    fontSize: 16,
    margin: 0,
    lineHeight: '28px',
    marginBottom: 25,
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 360,
    minWidth: 330,
    width: '100%',
  },
  backButton: {
    flex: 1,
    height: 50,
    fontSize: 16,
    borderRadius: 5,
    padding: '11px 41px 13px',
    color: '#291B4F',
    lineHeight: '24px',
    textTransform: 'none',
    background: '#FCD42B',
    boxShadow: 'none',
    '&:hover': {
      background: '#FCD42B',
    },
  },
});

export default useStyles;
