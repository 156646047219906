import { APIError, APIErrorType } from '../api.types';

// this abomination should not exist
// TODO: write proper error classes
export class TGBError extends Error {
  details: APIError | undefined;

  code: string | undefined;

  fromAPIError(details: APIError) {
    let message = '';
    if (details) {
      if (details.errorType === APIErrorType.Validation) {
        message = details.meta.validationErrorMessage;
      } else if (details.errorType === APIErrorType.GeminiRequestError) {
        const { msg, reason, errorMessage } = details.meta;

        message = reason || errorMessage || msg;
      } else {
        message = details.message;
      }
    }
    this.code = (details.meta as Record<string, any>).errorCode;
    this.message = message || this.message;
    this.details = details;

    return this;
  }

  getValidationErrorDetails() {
    if (this.details?.errorType === APIErrorType.Validation) {
      return this.details.meta.failedAttributes.reduce((obj, attr) => {
        const cloned = { ...obj };
        cloned[attr.attributeName] = attr.message;
        return cloned;
      }, {});
    }

    return null;
  }
}

export default TGBError;
