import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';

import { Button } from 'components';
import IconCheck from 'components/icon/check';
import IconMetamask from 'components/icon/metamask';
import IconTGB from 'components/icon/tgb';
import IconWarning from 'components/icon/warning';
import OptionallyVisible from 'components/optionallyVisible';
import PageHeader from 'components/pageHeader/pageHeader';
import COLORS from 'theme/colors';
import Currency from 'types/currency';
import { DonationCredentials } from 'types/donation';
import { DonationPledge } from 'types/pledge';
import useReturnUrl from 'utils/hooks/useReturnUrl';
import {
  getSelectAddress,
  initiatePayment,
  requestSelectAccount,
} from 'utils/metamask';

import {
  KEYS,
  LABELS,
} from './keys';
import { useStyles } from './styles';

export interface MetamaskProps {
  selectedAddress: string | null;
  setAddress: (value: string | null) => void;
  pledge: DonationPledge;
  donation: DonationCredentials;
  goBack: () => void;
  resetFlowWithDesiredDonationType: () => void;
  currency: Currency;
  returnUrl: string | null;
  fetchCryptoDonationStatus: () => Promise<Boolean>;
}

export const Metamask = ({
  selectedAddress,
  setAddress,
  pledge,
  donation,
  goBack,
  resetFlowWithDesiredDonationType,
  currency,
  returnUrl,
  fetchCryptoDonationStatus,
}: MetamaskProps) => {
  const { classes, cx } = useStyles();
  const [animatingSuccess, setAnimatingSuccess] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tries, setTries] = useState<number>(1);
  const [header, setHeader] = useState<string>('');
  const navigateToReturnUrl = useReturnUrl({
    returnUrl,
    fetchCryptoDonationStatus,
  });

  const runSuccessTransitionAnimation = () => {
    setAnimatingSuccess(true);
    return new Promise((resolve) => {
      setTimeout(() => {
        setAnimatingSuccess(false);
        resolve(true);
      }, KEYS.SUCCESS_ANIMATION_DURATION);
    });
  };

  // TODO: Think about how to split the function and move the functionality to thunk
  const connectToMetamask = async () => {
    try {
      setLoading(true);
      let paymentAddress = getSelectAddress();
      if (!paymentAddress) {
        const nextAddress = await requestSelectAccount();
        if (!nextAddress) {
          setLoading(false);
          setError(LABELS.CONNECTION_ERROR);
          setHeader(LABELS.ERROR);
          return;
        }

        paymentAddress = nextAddress;
        await runSuccessTransitionAnimation();
        setAddress(nextAddress);
      }

      const result = await initiatePayment(paymentAddress as string, donation.donationAddress, pledge.amount, currency);
      if (result.error) {
        setLoading(false);
        if (result.error.code === KEYS.REJECTED_BY_USER_CODE) {
          setHeader(LABELS.REJECTED);
          setError(LABELS.REJECTED_MESSAGE);
          return;
        }

        setError(result?.error.message);
        setHeader(LABELS.ERROR);
        return;
      }

      await runSuccessTransitionAnimation();
      setHeader(LABELS.DONATION_COMPLETE);
      setLoading(false);
    } catch (er) {
      setLoading(false);
      setError(LABELS.CONNECTION_ERROR);
      setHeader(LABELS.ERROR);
    }
  };

  const tryAgain = () => {
    setError(null);
    setTries(tries + 1);
  };

  useEffect(() => {
    connectToMetamask();
  }, [tries]);

  if (loading) {
    const processingMessage = selectedAddress ? LABELS.PROCESSING : LABELS.CONNECTING;
    const processedMessage = selectedAddress ? LABELS.PROCESSED : LABELS.CONNECTED;

    return (
      <Container className={classes.progressContainer}>
        <div className={classes.column}>
          <div className={cx(classes.row, {
            [classes.animatedRow]: !animatingSuccess,
          })}
          >
            <IconTGB width={40} height={46} />
            <IconCheck
              width={15}
              height={15}
              color={COLORS.PRIMARY}
              className={cx({
                [classes.successCheck]: animatingSuccess,
                [classes.hidden]: !animatingSuccess,
              })}
            />
            <IconMetamask width={40} height={40} />
          </div>
          <div className={cx(classes.message, {
            [classes.fadeOut]: animatingSuccess,
          })}
          >
            {processingMessage}
          </div>
          <div className={cx(classes.message, classes.successMessage, {
            [classes.fadeIn]: animatingSuccess,
            [classes.invisible]: !animatingSuccess,
          })}
          >
            {processedMessage}
          </div>
        </div>
      </Container>
    );
  }

  const subHeader = error || LABELS.THANK_YOU_MESSAGE;
  const actionButtonMessage = error ? LABELS.TRY_AGAIN : LABELS.START_OVER;
  const actionButtonCallback = error ? tryAgain : resetFlowWithDesiredDonationType;
  const hasReturnUrl = Boolean(returnUrl);

  return (
    <div className={classes.page}>
      <OptionallyVisible visible={Boolean(error)}>
        <PageHeader onGoBack={goBack} withBackButton />
      </OptionallyVisible>
      <Container className={classes.pageContainer}>
        <div className={classes.column}>
          <OptionallyVisible visible={!error}>
            <IconTGB className={classes.icon} width={50} height={56} />
          </OptionallyVisible>
          <OptionallyVisible visible={Boolean(error)}>
            <IconWarning className={classes.icon} />
          </OptionallyVisible>
          <div className={classes.header}>{header}</div>
          <div className={classes.subHeader}>{subHeader}</div>
          <OptionallyVisible visible={!error && hasReturnUrl}>
            <Button onClick={navigateToReturnUrl} className={classes.readyButton}>{LABELS.CLICK_WHEN_READY}</Button>
          </OptionallyVisible>
          <OptionallyVisible visible={!hasReturnUrl || Boolean(error)}>
            <Button onClick={actionButtonCallback}>{actionButtonMessage}</Button>
          </OptionallyVisible>
        </div>
      </Container>
    </div>
  );
};

export default Metamask;
