import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';

import BottomButtons from 'components/bottomButtons/bottomButtons';
import SignatureInput from 'components/inputs/signature/signature';
import Loading from 'components/loading';

import LABELS from './keys';
import useStyles from './styles';

interface SigningPageProps {
  signDonationPledge: () => void;
  goBack: () => void;
  updateSignature: (value: string) => void;
  isSubmitDisabled?: boolean;
  submitting: boolean;
}
export const SigningPage = ({
  signDonationPledge,
  goBack,
  updateSignature,
  isSubmitDisabled,
  submitting,
}: SigningPageProps) => {
  const [isNoticeVisible, setNoticeVisible] = useState<boolean>(Boolean(window?.tgbConfig?.transferNoticeEnabled));
  const [showFullAgreement, setShowFullAgreement] = useState<boolean>(false);
  const { classes } = useStyles();

  const handleSignatureUpdate = (value: string) => {
    updateSignature(value);
  };

  const handleSignatureClear = () => {
    updateSignature('');
  };

  const toggleAgreement = () => {
    setShowFullAgreement(!showFullAgreement);
  };

  const skipToSignature = () => {
    setNoticeVisible(false);
  };

  if (submitting) {
    return (
      <Container className={classes.page}>
        <Loading text={LABELS.SIGNING} />
      </Container>
    );
  }

  if (isNoticeVisible) {
    return (
      <>
        <Container className={classes.transferNoticePage}>
          <Typography className={classes.transferNoticeTitle}>
            {LABELS.TRANSFER_NOTICE_TITLE}
          </Typography>
          <Typography className={classes.transferNoticeText}>
            {LABELS.TRANSFER_NOTICE_TEXT}
          </Typography>
        </Container>
        <BottomButtons
          rightButtonText={LABELS.NEXT_BUTTON_LABEL}
          onClickRight={skipToSignature}
          leftButtonText={LABELS.BACK_BUTTON_LABEL}
          onClickLeft={goBack}
        />
      </>
    );
  }

  return (
    <>
      <Container className={classes.page}>
        <Typography className={classes.pageTitle}>
          {LABELS.PAGE_TITLE}
        </Typography>
        <div className={classes.signatureField}>
          <SignatureInput
            label={LABELS.SIGNATURE_FIELD_LABEL}
            onChange={handleSignatureUpdate}
            onClear={handleSignatureClear}
            width={220}
            compact={showFullAgreement}
            height={showFullAgreement ? 100 : 130}
          />
        </div>
        <div className={classes.agreementContainer}>
          <Typography className={classes.agreement}>
            {showFullAgreement ? LABELS.SIGNATURE_AGREEMENT : LABELS.SIGNATURE_AGREEMENT_SHORT}
            <button type="button" onClick={toggleAgreement} className={classes.showMore}>
              {showFullAgreement ? LABELS.SHOW_LESS : LABELS.SHOW_MORE}
            </button>
          </Typography>
        </div>
      </Container>
      <BottomButtons
        rightButtonText={LABELS.NEXT_BUTTON_LABEL}
        onClickRight={signDonationPledge}
        leftButtonText={LABELS.BACK_BUTTON_LABEL}
        onClickLeft={goBack}
        isDisabledSubmit={isSubmitDisabled}
      />
    </>
  );
};

export default SigningPage;
