import checkIsFiatDonationType from 'utils/workflow';

import { DonationWorkflowType } from '../types/workflow';
import { PoweredByBrand } from './types';

export const getBrand = (workflowType: DonationWorkflowType): PoweredByBrand => {
  const isFiatDonation = checkIsFiatDonationType(workflowType);

  if (isFiatDonation) {
    return PoweredByBrand.Shift4;
  }

  return PoweredByBrand.TGB;
};

export default getBrand;
