import { makeStyles } from 'tss-react/mui';

import FORM_SIZE from 'constants/window';
import COLORS from 'theme/colors';

const useStyles = makeStyles()(theme => ({
  root: {
    border: `1px solid ${COLORS.INPUT.BORDER}`,
    borderRadius: 5,
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      maxHeight: FORM_SIZE.VERSION_1.HEIGHT,
      minHeight: FORM_SIZE.VERSION_1.HEIGHT,
      maxWidth: FORM_SIZE.VERSION_1.WIDTH,
      minWidth: FORM_SIZE.VERSION_1.WIDTH,
    },

    [`@media (max-height: ${FORM_SIZE.VERSION_1.HEIGHT}px)`]: {
      '#root': {
        alignItems: 'flex-start',
      },
    },
  },
  rootLogos: {
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    top: 30,
    left: 30,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& img:nth-of-type(1)': {
      minHeight: 30,
    },
    '& img:nth-of-type(2)': {
      minHeight: 20,
    },
  },
  rootLogosSeparator: {
    borderLeft: `1px solid ${COLORS.GREY}`,
    margin: '0 20px',
  },
  container: {
    position: 'relative',
    padding: 0,
    boxSizing: 'border-box',
  },
  contentWrapper: {
    height: 474,
    overflowY: 'hidden',
    position: 'relative',
  },
  topBarContainer: {
    minHeight: 80,
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#291B4F',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    display: 'flex',
    alignContent: 'center',
    paddingBottom: 8,
  },
  imageContainer: {
    width: 50,
    height: 50,
    margin: '9px 15px 0 12px',
    borderRadius: 8,
    border: '4px solid white',
    overflow: 'hidden',
    alignContent: 'center',
    flexShrink: 0,
  },
  image: {
    height: 'auto',
    width: 50,
  },
  charityName: {
    cursor: 'default',
    pointerEvents: 'none',
    paddingTop: 10,
    textShadow: '2px 2px 4px #000000',
    color: '#ffffff',
    paddingRight: 5,
  },
  footer: {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    textDecoration: 'none',
    color: 'inherit',
  },
  footerText: {
    alignContent: 'top',
    padding: '8px 0 0 0',
    color: '#281b4f',
    fontSize: 10,
    textShadow: '2px 2px 4px #FFFFFF',
  },
  tgbLogo: {
    padding: '7px 0 0 15px',
    // width: 81,
    height: 24,
    objectFit: 'contain',
  },
  paper: {
    borderRadius: '8px 8px 0 0',
    border: 0,
    boxSizing: 'border-box',
    boxShadow: 'unset',
  },
  poweredContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
    border: 0,
    borderRadius: '0 0 8px 8px',
    width: '100%',
    borderTop: `1px solid ${COLORS.INPUT.BORDER}`,
    margin: 0,
    textDecoration: 'none',
    boxSizing: 'border-box',
    color: COLORS.TEXT.SECONDARY,
    fontSize: 12,
    backgroundColor: COLORS.GREY_BACKGROUND,
  },
  poweredLogo: {
    maxHeight: 20,
    marginLeft: 6,
  },
  poweredPrompt: {
    fontSize: 12,
  },
}));

export const styles = {
  paper: {
    borderRadius: 8,
  },
  header: (text: string) => ({
    fontSize: text.length > 30 ? '1rem' : '1.25rem',
  }),
};

export default useStyles;
