import React from 'react';

import { KEYS, LABELS } from './keys';
import useStyles from './styles';
import { PoweredByBrand } from './types';

interface PoweredByProps {
  brand: PoweredByBrand;
}

export const PoweredBy = ({
  brand = PoweredByBrand.TGB,
}: PoweredByProps) => {
  const { classes } = useStyles();
  const alt = LABELS.ALT[brand];
  const imageSrc = KEYS.LOGO[brand];
  const link = KEYS.LINK[brand];

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      className={classes.poweredContainer}
    >
      {LABELS.POWERED_BY}
      <img className={classes.poweredLogo} src={imageSrc} alt={alt} />
    </a>
  );
};

export default PoweredBy;
