import React, { useContext, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Sentry from '@sentry/react';

import { LABELS } from './captcha.keys';
import { CaptchaContext } from './captchaContext';

export const Captcha = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const context = useContext(CaptchaContext);

  if (!window?.tgbConfig?.reCaptchaSiteKey) {
    // eslint-disable-next-line
    console.warn(LABELS.NO_KEY);

    return null;
  }

  const onCaptchaError = () => {
    Sentry.captureMessage(LABELS.INIT_ERROR);
  };

  const onInit = () => {
    context.execute = async () => {
      try {
        const token = await recaptchaRef.current?.executeAsync();
        recaptchaRef.current?.reset();

        return token ?? null;
      } catch (e) {
        Sentry.captureException(e);
        return null;
      }
    };
  };

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      size="invisible"
      sitekey={window.tgbConfig.reCaptchaSiteKey}
      onErrored={onCaptchaError}
      asyncScriptOnLoad={onInit}
    />
  );
};

export default Captcha;
