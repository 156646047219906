/* eslint-disable react/no-array-index-key */
import React from 'react';

import { IconProps } from './icon.types';

const paths = [
  {
    path: [
      'M20 46 40 34.39 40 11.52 20 23 20 46Z',
    ].join(' '),
    fill: '#FAD325',
  },
  {
    path: 'M0 11.52 20 23 20 46 0 34.32 0 11.52Z',
    fill: '#CEEAE7',
  },
  {
    path: [
      'M0 11.52 20 0 40 11.52 20 23 0 11.52Z',
    ].join(' '),
    fill: '#261B4E',
  },
];

export const IconTGB = ({
  width = 40,
  height = 46,
  className,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 40 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {paths.map(({ path, fill }, key) => <path d={path} fill={fill} key={key} />)}
  </svg>
);

export default IconTGB;
