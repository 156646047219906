import { createTheme } from '@mui/material';

import componentSwitch from 'utils/componentSwitch';

import COLORS from './colors';

const light = createTheme({
  palette: {
    primary: {
      main: '#24292e',
    },
    secondary: {
      main: '#064c8f',
    },
  },
  typography: {
    // @ts-ignore
    useNextVariants: true,
  },
  colorTextSecondary: {
    color: 'red',
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
    MuiTextField: {
      variant: 'outlined',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
        },
        input: {
          fontSize: 14,
          height: '1.1876em',
          paddingTop: 8,
          paddingBottom: 8,
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
          '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(14px, 12px) scale(1)',
          },
        },
      },
    },
  },
});

const overlay = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
  },
  typography: {
    // @ts-ignore
    useNextVariants: true,
  },
  colorTextSecondary: {
    color: 'red',
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
    MuiTextField: {
      variant: 'outlined',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Noto Sans',
        },
      },
    },
  },
});

export const theme = componentSwitch(light, overlay);

export default theme;
