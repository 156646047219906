import queryString from 'query-string';

const DEFAULT_API_USER_UUID = 'widget';
const fallbackUserApiUuid = window?.tgbConfig?.apiUserUuid ?? DEFAULT_API_USER_UUID;

export const getApiUserUuid = () => queryString.parse(window.location.search).apiUserUuid?.toString() || fallbackUserApiUuid;

export const removeEmptyQueryParamEntries = (
  queryParamValue: string | (string | null)[] | null,
) => (
  Array.isArray(queryParamValue) ? queryParamValue.filter(Boolean) : queryParamValue
) as string | string[] | null;

export const getStringParam = (
  queryParamValue: string | (string | null)[] | null | undefined,
): string | undefined => {
  if (typeof queryParamValue === 'string') {
    return queryParamValue;
  }

  return undefined;
};

export default getApiUserUuid;
