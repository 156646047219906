import React from 'react';
import { Box, Button } from '@mui/material';

import { Logo } from 'components/logo';
import OptionallyVisible from 'components/optionallyVisible';

import useStyles from './styles';

interface ErrorScreenProps {
  error: string;
  hasBackButton: boolean;
  goBack: () => void;
  title?: string;
}

const KEYS = {
  BACK_BUTTON_TEXT: 'Go Back',
  TITLE: 'Error!',
};

const ErrorScreen = ({
  title,
  error,
  hasBackButton,
  goBack,
}: ErrorScreenProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Logo />
      <h3 className={classes.title}>{title || KEYS.TITLE}</h3>
      <h5 className={classes.label}>{error}</h5>
      <OptionallyVisible visible={hasBackButton}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Button
            onClick={goBack}
            className={classes.backButton}
          >
            {KEYS.BACK_BUTTON_TEXT}
          </Button>
        </Box>
      </OptionallyVisible>
    </div>
  );
};

export default ErrorScreen;
