import Frequency from 'types/frequency';

export const LABELS = {
  CHOOSE_FREQUENCY: 'Choose Frequency',
  FREQUENCY: {
    [Frequency.Once]: 'Once',
    [Frequency.Hour]: 'Hourly',
    [Frequency.Monthly]: 'Monthly',
    [Frequency.Annually]: 'Annually',
  },
  GO_BACK_BUTTON: 'Previous',
};

export const FREQUENCY_KEYS: Record<Frequency, string> = {
  [Frequency.Once]: 'once',
  [Frequency.Hour]: 'hour',
  [Frequency.Monthly]: 'month',
  [Frequency.Annually]: 'year',
};

export const AVAILABLE_FREQUENCIES: Partial<Record<Frequency, string>> = Object.entries(FREQUENCY_KEYS).reduce((acc, [key, value]) => {
  const envAvailableFrequencies = window?.tgbConfig?.availableFrequencies;
  const isFrequencyAvailableForTheGivenEnv = envAvailableFrequencies?.includes(value as Frequency)
    || value === Frequency.Once;
  if (!isFrequencyAvailableForTheGivenEnv) {
    return acc;
  }

  return ({ ...acc, [key]: value });
}, {});
