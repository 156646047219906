import shift4Logo from '../assets/images/shift4-logo.svg';
import tgbLogo from '../assets/images/TGB-logo.svg';
import { PoweredByBrand } from './types';

export const KEYS = {
  LINK: {
    [PoweredByBrand.TGB]: 'https://www.thegivingblock.com/',
    [PoweredByBrand.Shift4]: 'https://www.shift4.com/',
  },
  LOGO: {
    [PoweredByBrand.TGB]: tgbLogo,
    [PoweredByBrand.Shift4]: shift4Logo,
  },
  ELEMENTS_PER_ROW_LONG: 2,
  ELEMENTS_PER_ROW_SHORT: 3,
  SHORT_ITEMS_REQURED_LENGTH: 5,
  ROW_ITEM_SHORT: 4,
  ROW_ITEM_LONG: 6,
};

export const LABELS = {
  ALT: {
    [PoweredByBrand.TGB]: 'The Giving Block',
    [PoweredByBrand.Shift4]: 'Shift4',
  },
  POWERED_BY: 'Powered by',
};
