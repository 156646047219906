import { connect, MapDispatchToPropsParam, MapStateToPropsParam } from 'react-redux';
import qs from 'query-string';
import { Dispatch } from 'redux';

import { DEFAULT_USD_VALUES } from 'config/workflow';
import { BASIC_CURRENCIES } from 'constants/currencies';
import fetchCryptoDonationStatus from 'state/donation/thunk/fetchCryptoDonationStatus.thunk';
import setDonorInfo from 'state/donor/setDonorInfo';
import { initialState as initialDonorInfoState } from 'state/donor/state';
import changeFiatAmount from 'state/pledge/changeFiatAmount';
import fetchCurrencyExchangeRateSuccess from 'state/pledge/fetchCurrencyExchangeRate.success';
import updatePledge from 'state/pledge/updatePledge';
import { AppReduxState } from 'state/state';
import { initialState as initialBrokerInfoState } from 'state/stock/state';
import setBrokerInfo from 'state/stock/updateBrokerInfo';
import setAddress from 'state/wallet/metamask/setAddress';
import setType from 'state/workflow/setType';
import DonationWorkflowType from 'types/workflow';
import { componentSwitch, getUsedDesignVersion } from 'utils/componentSwitch';
import { getInitialWorkflowType } from 'utils/organization';
import checkIsFiatDonationType from 'utils/workflow';

import { Metamask as OverlayMetamask } from './overlayVariant/wallet/metamask';
import { Metamask } from './wallet/metamask';

interface DefaultStateParams {
  defaultAmount: number | null;
  defaultCryptocurrency: string | null;
}

const mapStateToProps: MapStateToPropsParam<any, any, AppReduxState> = (state) => {
  const { selectedAddress } = state.wallet.metamask;
  const { type: workflowType } = state.workflow;
  const { search } = window.location;
  const { display } = qs.parse(search);
  const { organization } = state.organization;
  const { defaultAmount, returnUrl, defaultCryptocurrency } = state.donationData;
  const {
    crypto,
  } = state.donation;

  const {
    donationAddress,
    fallbackAddressUsed,
  } = crypto;

  const {
    amount,
    currency,
  } = state.pledge;

  return {
    pledge: {
      amount,
      currency,
    },
    donation: {
      donationAddress,
      fallbackAddressUsed,
    },
    selectedAddress,
    workflowType,
    display,
    organization,
    defaultAmount,
    returnUrl,
    defaultCryptocurrency,
  };
};

const mapDispatchToProps: MapDispatchToPropsParam<any, any> = (dispatch: Dispatch<any>) => ({
  resetFlow: (workflowType: DonationWorkflowType | null) => dispatch(setType.createAction(workflowType)),
  clearState: (workflowType: DonationWorkflowType | null, {
    defaultCryptocurrency,
    defaultAmount,
  }: DefaultStateParams) => {
    // TODO: Refactor clearState, export this logic in a single place, as it is used on multiple occasions TGB-8261
    dispatch(setBrokerInfo.createAction(initialBrokerInfoState()));
    dispatch(setDonorInfo.createAction(initialDonorInfoState()));
    const isFiatDonationType = checkIsFiatDonationType(workflowType);
    if (isFiatDonationType) {
      dispatch(fetchCurrencyExchangeRateSuccess.createAction(1));
      dispatch(updatePledge.createAction({ currency: BASIC_CURRENCIES.USD }));
      dispatch(changeFiatAmount.createAction(DEFAULT_USD_VALUES.FIAT));
    } else {
      dispatch(updatePledge.createAction({ currency: defaultCryptocurrency ?? BASIC_CURRENCIES.BTC }));
      dispatch(changeFiatAmount.createAction(defaultAmount ?? DEFAULT_USD_VALUES.CRYPTO));
    }
  },
  setAddress: (value: string) => dispatch(setAddress.createAction(value)),
  fetchCryptoDonationStatus: () => dispatch(fetchCryptoDonationStatus()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { organization, workflowType } = stateProps;
  const version = getUsedDesignVersion();
  const donationType = getInitialWorkflowType(
    organization,
    workflowType,
    version,
  );

  const resetFlowWithDesiredDonationType = () => {
    dispatchProps.clearState(donationType, {
      defaultAmount: stateProps.defaultAmount,
      defaultCryptocurrency: stateProps.defaultCryptocurrency,
    });
    dispatchProps.resetFlow(donationType);
  };

  const clearState = () => (
    dispatchProps.clearState(workflowType, {
      defaultAmount: stateProps.defaultAmount,
      defaultCryptocurrency: stateProps.defaultCryptocurrency,
    })
  );

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    clearState,
    resetFlowWithDesiredDonationType,
  };
};

export const MetamaskConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(Metamask, OverlayMetamask));

export default MetamaskConnect;
