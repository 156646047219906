import { Dispatch } from 'redux';

import { API, Endpoints } from 'api';
import { Currency } from 'types/currency';

import fetchCurrenciesFail from '../fetchCurrencies.fail';
import fetchCurrenciesStart from '../fetchCurrencies.start';
import fetchCurrenciesSuccess from '../fetchCurrencies.success';

export const DEFAULT_ERROR_CODE = 'UNEXPECTED_ERROR';

export const fetchCurrencies = () => async (dispatch: Dispatch<any>) => {
  dispatch(fetchCurrenciesStart.createAction());

  try {
    const response = await API.post(Endpoints.currencyList);

    if (!response?.data) {
      dispatch(fetchCurrenciesFail.createAction({
        errorMessage: DEFAULT_ERROR_CODE,
      }));
      return;
    }

    const { data } = response;
    const currencies: Currency[] = data;

    dispatch(fetchCurrenciesSuccess.createAction(currencies));
  } catch (e: any) {
    const errorMessage = e.message || DEFAULT_ERROR_CODE;
    dispatch(fetchCurrenciesFail.createAction({ errorMessage }));
  }
};

export default fetchCurrencies;
