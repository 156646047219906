import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

export const breatheAnimation = keyframes`
  0%, 20%, 80%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  30%, 70% {
    opacity: 0.7;
    transform: scale(1.2);
  }
`;

const useStyles = makeStyles()({
  loadingIcon: {
    animation: `${breatheAnimation} 1.8s linear infinite`,
  },
  icon: {
    width: 150,
  },
  label: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '38px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 360,
    width: '100%',
  },
  '@keyframes breathe': {
    '0%, 20%, 80%, 100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
    '30%, 70%': {
      opacity: 0.7,
      transform: 'scale(1.2)',
    },
  },
});

export default useStyles;
