import React, { ChangeEvent } from 'react';
import { Checkbox as MUICheckbox, FormControlLabel } from '@mui/material';

import COLORS from 'theme/colors';
import { locators, QALocator } from 'utils/tests/QA';

import IconCheck from '../icon/check';
import { CheckboxProps } from './checkbox.types';
import useStyles from './styles';

export const Checkbox = ({
  label,
  name,
  value,
  onChange,
  disabled = false,
}: CheckboxProps) => {
  const { classes } = useStyles();
  const handleChange = (event: ChangeEvent<{}>, checked: boolean) => {
    onChange(checked);
  };

  return (
    <FormControlLabel
      control={(
        <MUICheckbox
          disabled={disabled}
          checked={Boolean(value)}
          className={classes.checkbox}
          {...QALocator(locators.components.common.checkbox)}
          checkedIcon={(
            <IconCheck
              color={COLORS.PRIMARY}
              className={classes.checkedIcon}
              width={20}
              height={20}
            />
          )}
        />
      )}
      label={label}
      name={name}
      onChange={handleChange}
      value={value}
    />
  );
};

export default Checkbox;
