import React from 'react';

import { KEYS, LABELS } from '../keys';
import { PoweredByBrand } from '../types';
import useStyles from './styles';

export interface FooterProps {
  brand: PoweredByBrand;
}

export const Footer = ({
  brand = PoweredByBrand.TGB,
}: FooterProps) => {
  const { classes } = useStyles({ isOverlay: true });

  const alt = LABELS.ALT[brand];
  const imageSrc = KEYS.LOGO[brand];
  const link = KEYS.LINK[brand];
  const imageClassMap = {
    [PoweredByBrand.TGB]: classes.tgbLogo,
    [PoweredByBrand.Shift4]: classes.shift4Logo,
  };
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={classes.footer}
      href={link}
    >
      <span className={classes.footerText}>
        {LABELS.POWERED_BY}
      </span>
      <img className={imageClassMap[brand]} src={imageSrc} alt={alt} />
    </a>
  );
};

export default Footer;
