import React, {
  useEffect,
} from 'react';

import LoadingIcon from 'components/loading/loadingIcon';

import { LABELS } from './keys';
import { useStyles } from './styles';

interface MandateInfoScreenProps {
  proceed: () => void;
}

export const MandateInfoScreen = ({
  proceed,
}: MandateInfoScreenProps) => {
  const { classes } = useStyles();

  useEffect(() => {
    proceed();
  }, []);

  return (
    <div className={classes.scrollContainer}>
      <LoadingIcon className={classes.loadingIcon} />
      <div className={classes.processingLabel}>{LABELS.LOADING}</div>
    </div>
  );
};

export default MandateInfoScreen;
