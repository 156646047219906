import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import { AchDonationWorkflowStep } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';
import { getAvailableFlows } from 'utils/organization';

import ErrorBlock from '../errorBlock';
import OverlayErrorBlock from '../overlayVariant/errorBlock';

const GENERIC_ERROR = 'Something went wrong';

const mapStateToProps = (state: AppReduxState) => {
  const { organization } = state.organization;

  const availableFlows = getAvailableFlows(organization);
  const achErrorMessage = state.donation.ach.error?.errorMessage ?? GENERIC_ERROR;

  return ({
    availableFlows,
    error: state.workflow.error.message || achErrorMessage,
    title: state.workflow.error.title,
    hasBackButton: state.organization?.organization?.id,
  });
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  goBack: () => {
    dispatch(changeStep.createAction(AchDonationWorkflowStep.FillDonorInfo));
  },
});

export const ErrorConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(componentSwitch(ErrorBlock, OverlayErrorBlock));

export default ErrorConnect;
