import facebookLogo from 'assets/images/facebook.svg';
import linkedinLogo from 'assets/images/linkedin.svg';
import twitterLogo from 'assets/images/twitter.svg';

export const LABELS = {
  CARD_SUCCESS_TITLE: 'Thank You!',
  CARD_SUCCESS_TEXT_SUBTITLE: '1 out of 4 people you share this with are likely to donate',
  STOCK_SUCCESS_TITLE: 'Thank You',
  STOCK_SUCCESS_TEXT_SUBTITLE: ['Your pledge will be sent to your broker! You will receive a tax',
    'receipt from us once your donation is complete.'].join(' '),
  STOCK_SUCCESS_TEXT_SUBTITLE_GENERAL: 'Form has been sent to your email address! Please download, sign & send to your broker.',
  RESET_BUTTON_LABEL: 'Start Over',
};

export const DEFAULT_WEBSITE_LINK = 'https://thegivingblock.com';

export const KEYS = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  OTHER_BROKER: 'other',
  TWITTER_DONATION_MESSAGE: (organizationName: string) => `I just made a donation to ${
    organizationName
  } using @TheGivingBlock Check them out here - ${DEFAULT_WEBSITE_LINK}`,
};

export const getLinkButtons = (organizationName: string) => ([
  {
    key: KEYS.FACEBOOK,
    icon: facebookLogo,
    linkDescription: 'Share on Facebook',
    link: `https://www.facebook.com/sharer.php?u=${encodeURIComponent(DEFAULT_WEBSITE_LINK)}`,
    backgroundColor: '#1877F2',
  },
  {
    key: KEYS.TWITTER,
    icon: twitterLogo,
    linkDescription: 'Share on Twitter',
    link: `https://twitter.com/intent/tweet?text=${encodeURIComponent(KEYS.TWITTER_DONATION_MESSAGE(
      organizationName,
    ))}`,
    backgroundColor: '#03A9F4',
  },
  {
    key: KEYS.LINKEDIN,
    icon: linkedinLogo,
    linkDescription: 'Share on Linkedin',
    link: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(DEFAULT_WEBSITE_LINK)}`,
    backgroundColor: '#0077B5',
  },
]);

export default LABELS;
