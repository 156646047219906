import { DonorInfo } from 'types/donor';

import { AnyAction } from '../state.types';
import { DonorReduxState } from './state';

export const actionTypeName = 'DONOR__SET_DONOR_INFO';

export type ActionSetDonorInfo = AnyAction & {
  field: string;
  donor: DonorInfo;
}

export const createAction = (donor: DonorInfo) => ({
  type: actionTypeName,
  donor,
});

export const reducer = (
  state: DonorReduxState,
  action: ActionSetDonorInfo,
): DonorReduxState => {
  const { donor } = action;
  if (!donor) {
    return state;
  }

  return {
    ...state,
    ...donor,
  };
};

export default {
  actionTypeName,
  createAction,
  reducer,
};
