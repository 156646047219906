import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AppReduxState } from 'state/state';
import { ApplePayEventAction, TGB_WIDGET_CHANNEL } from 'types/applePay';

import KEYS from './keys';

interface ApplePayAvailableResponse {
  channel: string;
  action: ApplePayEventAction;
  isApplePayAvailable: boolean;
}

const organizationSelector = (state: AppReduxState) => state.organization.organization;
const widgetStateSelector = (state: AppReduxState) => state.widget;

export const useApplePayAvailability = () => {
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const { areApplePayEnabled } = useSelector(organizationSelector);
  const { widgetId } = useSelector(widgetStateSelector);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    window.parent.postMessage({
      channel: TGB_WIDGET_CHANNEL,
      action: ApplePayEventAction.isApplePayAvailable,
      widgetId,
    }, KEYS.UNSPECIFIED_ORIGIN);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleMessage = useCallback((event: MessageEvent<ApplePayAvailableResponse>) => {
    if (event.data.channel === TGB_WIDGET_CHANNEL && event.data.action === ApplePayEventAction.isApplePayAvailable) {
      setIsApplePayAvailable(event.data.isApplePayAvailable);
    }
  }, []);

  return isApplePayAvailable && areApplePayEnabled;
};

export default useApplePayAvailability;
