import { connect, MapStateToPropsParam } from 'react-redux';

import {
  ACH_STEPS,
  CREDIT_CARD_STEPS,
  CRYPTO_STEPS,
  DAF_STEPS,
  STOCKS_STEPS,
} from 'config/workflow';
import { AppReduxState } from 'state/state';
import DonationWorkflowType, { WorkflowStep } from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';

import { Layout } from './layout';
import { Layout as OverlayLayout } from './overlayVariant/layout';

const mapStateToProps: MapStateToPropsParam<any, any, AppReduxState> = (state, ownProps) => {
  const { type: workflowType } = state.workflow;
  const stepsMap: Record<DonationWorkflowType, { title: string; key: WorkflowStep }[]> = {
    [DonationWorkflowType.Crypto]: CRYPTO_STEPS,
    [DonationWorkflowType.CreditCard]: CREDIT_CARD_STEPS,
    [DonationWorkflowType.Stock]: STOCKS_STEPS,
    [DonationWorkflowType.Daf]: DAF_STEPS,
    [DonationWorkflowType.Ach]: ACH_STEPS,
  };
  const steps = workflowType === null ? [] : stepsMap[workflowType];

  return {
    organization: state.organization.organization,
    step: state.workflow.step,
    children: ownProps.children,
    steps,
    workflowType,
  };
};

export const LayoutConnect = connect(
  mapStateToProps,
)(componentSwitch(Layout, OverlayLayout));

export default LayoutConnect;
