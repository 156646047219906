import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import createPaymentIntentAndSubmitPledge from 'state/donation/thunk/createPaymentIntentAndSubmitPledge.thunk';
import setAnonymous from 'state/donor/setAnonymous';
import { initialState as getInitialDonorState } from 'state/donor/state';
import updateDonorInfoValue from 'state/donor/updateDonorInfoValue';
import { AppReduxState } from 'state/state';
import changeStep from 'state/workflow/changeStep';
import submitPledge from 'state/workflow/thunk/submitPledge.thunk';
import submitStockDonationPledge from 'state/workflow/thunk/submitStockDonationPledge';
import { DonorInfo } from 'types/donor';
import Frequency from 'types/frequency';
import { DonationPledge, StocksDonationPledge } from 'types/pledge';
import DonationWorkflowType, {
  CreditCardDonationWorkflowStep,
  CryptoDonationWorkflowStep,
  DafDonationWorkflowStep,
  StockDonationWorkflowStep,
  WorkflowStep,
} from 'types/workflow';
import componentSwitch from 'utils/componentSwitch';
import { pushDataLayerEvent } from 'utils/gtm';
import GTMEventType from 'utils/gtm/types';
import eventDataFactory from 'utils/gtm/util';

import DonorInfoScreen from './donorInfo';
import OverlayDonorInfoScreen from './overlayVariant/donorInfo';

const mapStateToProps = (state: AppReduxState) => {
  const {
    type: workflowType,
    step,
  } = state.workflow;

  const { externalId } = state.donationData;

  const {
    organization: organizationInfo,
  } = state.organization;

  const {
    isSubmitting,
    crypto,
  } = state.donation;

  const {
    fallbackAddressUsed,
  } = crypto;

  const {
    fundraiserId,
  } = state.fundraiser;

  const {
    frequency,
  } = state.frequency;

  const isStockDonation = workflowType === DonationWorkflowType.Stock;

  const organization = {
    ...organizationInfo,
    allowsAnon: isStockDonation ? false : organizationInfo.allowsAnon,
  };

  const defaultEmptyState = getInitialDonorState();

  const isCardRecurringDonation = workflowType === DonationWorkflowType.CreditCard && frequency !== Frequency.Once;

  const donationPledge: DonationPledge | StocksDonationPledge = {
    currency: state.pledge.currency,
    amount: state.pledge.amount,
    usdAmount: state.pledge.usdAmount,
    exchangeRate: state.pledge.exchangeRate,
    notes: state.pledge.notes,
    coverTransactionFees: state.pledge.coverTransactionFees,
    fundsDesignation: state.pledge.fundsDesignation,
    areNotesEnabled: state.pledge.areNotesEnabled,
    areTributesEnabled: state.pledge.areTributesEnabled,
    honoreeEmail: state.pledge.honoreeEmail,
    honoreeName: state.pledge.honoreeName,
    notifyHonoree: state.pledge.notifyHonoree,
    asset: state.pledge.asset,
    quantity: state.pledge.quantity,
    campaignId: state.pledge.campaignId,
  };

  const personalInfoEventData = eventDataFactory(GTMEventType.PersonalInfo, workflowType!, { state });
  const donationSentEventData = eventDataFactory(GTMEventType.DonationSent, workflowType!, { state });

  return {
    personalInfoEventData,
    donationSentEventData,
    donor: state.donor,
    defaultEmptyState,
    pledge: donationPledge,
    organization,
    isSubmitting,
    workflowType,
    fundraiserId,
    fallbackAddressUsed,
    pledgeError: state.pledge.error,
    isRecurringDonation: isCardRecurringDonation,
    step,
    areNotesEnabled: state.pledge.areNotesEnabled,
    areTributesEnabled: state.pledge.areTributesEnabled,
    externalId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  goBack: (step: WorkflowStep) => {
    dispatch(changeStep.createAction(step));
    dispatch(setAnonymous.createAction(false));
  },
  updateValue: (field: string, value: any) => dispatch(updateDonorInfoValue.createAction(field, value)),
  proceedWithDafPledge: (donorInfo: DonorInfo) => {
    Object.entries(donorInfo)
      .forEach(([field, value]) => dispatch(updateDonorInfoValue.createAction(field, value)));
    dispatch(changeStep.createAction(DafDonationWorkflowStep.Donate));
  },
  submitStockDonationPledge: ({
    pledge,
    organization,
    donor,
    fundraiserId,
    externalId,
  }) => dispatch(submitStockDonationPledge({
    pledge,
    organization,
    donor,
    fundraiserId,
    externalId,
  })),
  submitPledge: ({
    pledge,
    organization,
    donor,
    fundraiserId,
    captchaToken,
    externalId,
  }) => dispatch(submitPledge({
    pledge,
    organization,
    donor,
    fundraiserId,
    captchaToken,
    externalId,
  })),
  createPaymentIntentAndSubmitPledge: ({
    organization,
    pledge,
    donor,
    fundraiserId,
    externalId,
  }) => dispatch(createPaymentIntentAndSubmitPledge({
    organization,
    pledge,
    donor,
    fundraiserId,
    externalId,
  })),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
) => {
  const { workflowType, fundraiserId } = stateProps;

  const submitPledge = (donorInfo: DonorInfo, captchaToken: string | null) => {
    if (workflowType === DonationWorkflowType.CreditCard) {
      return dispatchProps.createPaymentIntentAndSubmitPledge({
        organization: stateProps.organization,
        pledge: stateProps.pledge as DonationPledge,
        donor: donorInfo,
        fundraiserId,
        externalId: stateProps.externalId,
      });
    }

    if (workflowType === DonationWorkflowType.Stock) {
      return dispatchProps.submitStockDonationPledge({
        pledge: stateProps.pledge,
        organization: stateProps.organization,
        donor: donorInfo,
        fundraiserId,
        externalId: stateProps.externalId,
      });
    }

    if (workflowType === DonationWorkflowType.Daf) {
      return dispatchProps.proceedWithDafPledge(donorInfo);
    }

    return dispatchProps.submitPledge({
      pledge: stateProps.pledge,
      organization: stateProps.organization,
      donor: donorInfo,
      fundraiserId,
      captchaToken,
      externalId: stateProps.externalId,
    });
  };

  const onProcess = () => {
    pushDataLayerEvent(stateProps.personalInfoEventData);
  };

  const goBack = () => {
    const {
      step,
      areNotesEnabled,
      areTributesEnabled,
      workflowType,
    } = stateProps;

    if (!step || !workflowType) {
      return;
    }

    const shouldShowNotesStep = areNotesEnabled || areTributesEnabled;
    const fallbackNextWorkflowStep = step - 1;

    const previousWorkflowStepPerWorkflowType = {
      [DonationWorkflowType.Crypto]:
        shouldShowNotesStep
          ? CryptoDonationWorkflowStep.Notes : CryptoDonationWorkflowStep.Pledge,
      [DonationWorkflowType.CreditCard]:
        shouldShowNotesStep
          ? CreditCardDonationWorkflowStep.Notes : CreditCardDonationWorkflowStep.Pledge,
      [DonationWorkflowType.Stock]:
        shouldShowNotesStep
          ? StockDonationWorkflowStep.Notes : StockDonationWorkflowStep.Pledge,
    };

    const previousStep = previousWorkflowStepPerWorkflowType[workflowType] ?? fallbackNextWorkflowStep;

    dispatchProps.goBack(previousStep);
  };

  return {
    ...stateProps,
    ...dispatchProps,
    submitPledge,
    onProcess,
    goBack,
  };
};

export const DonorInfoConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(componentSwitch(DonorInfoScreen, OverlayDonorInfoScreen));

export default DonorInfoConnect;
