import React from 'react';
import { Field } from 'formik';

import { Checkbox } from 'components/checkbox';
import { DonorInfo } from 'types/donor';

import { KEYS } from './fields.keys';
import { FormFieldDescription } from './fields.types';
import { useStyles, withCheckboxStyles } from './styles';

interface CheckboxComponentProps {
  label: string;
  checked: boolean;
}

const CheckboxComponent = ({
  label,
  checked,
  ...props
}: CheckboxComponentProps) => (
  <Checkbox
    color="default"
    value={checked}
    {...props}
  />
);

const CustomCheckbox = withCheckboxStyles(CheckboxComponent);

interface CheckboxFieldProps {
  item: FormFieldDescription;
  setFieldValue: (field: string, value: any) => void;
  values: DonorInfo;
}

export const CheckboxField = ({ item, setFieldValue, values }: CheckboxFieldProps) => {
  const { cx, classes } = useStyles({});

  const handleCheckboxChange = () => {
    setFieldValue(item.name, !values[item.name]);
  };

  const isCleanView = item.view === KEYS.CLEAN_VIEW;

  return (
    <div
      key={item.label}
      className={cx(classes.checkboxContainer, {
        [classes.cleanCheckboxContainer]: isCleanView,
      })}
    >
      <Field
        label={item.label}
        type="checkbox"
        name={item.name}
        required={values[item.name] ? false : item.isRequired}
        fullWidth
        component={CustomCheckbox}
        id="outlined-dense"
        margin="dense"
        checked={Boolean(values[item.name])}
        onChange={handleCheckboxChange}
      />
      <div
        role="checkbox"
        onKeyDown={() => {}}
        aria-checked={values[item.name]}
        className={classes.subscriptionText}
        tabIndex={0}
        onClick={handleCheckboxChange}
      >
        {item.label}
      </div>
    </div>
  );
};

export default CheckboxField;
