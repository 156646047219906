export const BASIC_CURRENCIES = {
  BTC: 'btc',
  ETH: 'eth',
  USD: 'USD',
} as const;

export const CRYPTO_CURRENIES = {
  BTC: 'btc',
  ETH: 'eth',
  USDC: 'usdc',
  CUBE: 'cube',
  CVC: 'cvc',
  GALA: 'gala',
  GRT: 'grt',
  GUSD: 'gusd',
  QRDO: 'qrdo',
  STORJ: 'storj',
  USDT: 'usdt',
  SLP: 'slp',
  XRP: 'xrp',
};

export const defaultSubscriptions = {
  name: 'The Giving Block',
  description: 'Donations powered by cryptocurrency',
  subscriptionId: '1',
  isChecked: false,
};

export const givingBlockDetails = {
  logo: 'tgbLogo',
  name: 'The Giving Block',
  website: 'https://thegivingblock.com',
  supportWebsite: 'https://thegivingblock.com/support',
  widgetFooterText: 'Powered by ',
};
