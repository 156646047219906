export const LABELS = {
  SIGNATURE_AGREEMENT: ['By signing my donation request electronically I hereby consent',
    'to contracting electronically and agree that such signature will be legally equivalent',
    'to a manual paper signature. I hereby consent to having the donation request information',
    'I provided through this process compiled together with this signature into the broker-specific',
    'required forms. I acknowledge that I will later receive a record of this form and have',
    'the ability to maintain my own records of the same, whether in digital or hard-copy form.',
    'If you have any questions please reach out to operations@thegivingblock.com'].join(' '),
  SIGNATURE_AGREEMENT_SHORT: ['By signing my donation request electronically I hereby consent to',
    'contracting electronically and agree that such signature will be legally equivalent to a',
    'manual paper signature'].join(' '),
  NEXT_BUTTON_LABEL: 'Continue',
  BACK_BUTTON_LABEL: 'Previous',
  CLEAR_BUTTON_LABEL: 'Clear',
  PAGE_TITLE: 'Donor Signature',
  TRANSFER_NOTICE_TITLE: 'Transfer Notice',
  TRANSFER_NOTICE_TEXT: ['If you require your transfer to be processed before year end, we',
    'recommend working directly with your broker to expedite your pledge once submitted.'].join(' '),
  SIGNATURE_FIELD_LABEL: 'Your Signature',
  SIGNING: 'Signing your pledge',
  SHOW_MORE: 'show more',
  SHOW_LESS: 'show less',
};

export default LABELS;
