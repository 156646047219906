import React from 'react';

import { Logo } from '../logo';
import useStyles from './styles';

export interface LoadingIconProps {
  className?: string;
}

export const LoadingIcon = ({
  className,
}: LoadingIconProps) => {
  const { classes, cx } = useStyles();

  return (
    <Logo className={cx(classes.loadingIcon, className)} />
  );
};

export default LoadingIcon;
